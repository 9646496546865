export const readCSV = (file, hasHeader = true, maxSize = 1) => {
  return new Promise((resolve, reject) => {
    // file extension validation
    if (!file.name.endsWith(".csv")) {
      reject(new Error(`ファイルの形式がCSVではありません。`));
      return;
    }

    // Check file size
    const sizeInMB = file.size / (1024 * 1024);
    if (sizeInMB > maxSize) {
      // TODO: エラーメッセージの設定の機構を検討する。
      reject(new Error(`ファイルの最大サイズ(${maxSize}MB)を超えました。`));
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const contents = e.target.result;
      const lines = contents.split("\n").map((line) => line.trim()); // trim each line
      const data = [];
      for (let i = 0; i < lines.length; i++) {
        if (lines[i] === "") continue; // skip empty lines
        if (i === 0 && hasHeader) continue; // skip the header line if hasHeader is true
        data.push(lines[i].split(",")); // split line into columns
      }
      resolve(data);
    };
    reader.onerror = () => {
      reject(new Error("Failed to read file"));
    };
    reader.readAsText(file);
  });
};

/**
 * Downloads a CSV file with the specified filename and data.
 * @param {string} filename - The filename for the downloaded file.
 * @param {string} data - The CSV data to be downloaded.
 *
 * Example CSV data format:
 * "Name, Age, Gender\nJohn, 25, Male\nJane, 30, Female\n"
 */
export const downloadCSV = (filename, data) => {
  // Add BOM to ensure Excel displays the data correctly
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, data], { type: "text/csv" });
  // Create URL object from Blob
  const url = (window.URL || window.webkitURL).createObjectURL(blob);
  // Create link with URL and filename
  const download = document.createElement("a");
  download.href = url;
  download.download = filename;
  // Trigger click event on link to download file
  download.click();
  // Revoke URL object to free up memory
  (window.URL || window.webkitURL).revokeObjectURL(url);
};

export const downloadText = async (filename, text) => {
  return new Promise((resolve, reject) => {
    let a = null;
    try {
      const blob = new Blob([text], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      resolve();
    } catch (error) {
      reject(error);
    } finally {
      if (a !== null || a !== undefined) {
        document.body.removeChild(a);
      }
    }
  });
};
