import { CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MicIcon from "@mui/icons-material/Mic";
import { ButtonWithAnalytics } from "./ComponentWithAnalytics";
import { PropTypes } from "prop-types";

export const RecordButton = ({
  sx,
  isRecording,
  messageInput,
  isStartingRecord,
  handleClickRecordButton,
}) => {
  const _sx = sx;

  let variant = "contained";
  if (isRecording || (!isRecording && messageInput)) {
    variant = "outlined";
  }

  if (isStartingRecord) {
    return (
      <ButtonWithAnalytics
        variant="contained"
        color="primary"
        disabled
        startIcon={
          <CircularProgress
            size={15}
            color="inherit"
            sx={{
              mr: "-0.2rem",
            }}
          />
        }
        sx={{ ml: "0.4rem", ..._sx }}
      >
        音声入力起動中
      </ButtonWithAnalytics>
    );
  }

  return (
    <ButtonWithAnalytics
      eventName="clickRecordButton"
      id="record-Button"
      variant={variant}
      color={isRecording ? "monoqlo" : "primary"}
      onClickFunc={handleClickRecordButton}
      startIcon={
        isRecording ? (
          <CloseIcon sx={{ width: "15", margin: "-0.2rem" }} />
        ) : (
          <MicIcon sx={{ width: "15", margin: "-0.2rem" }} />
        )
      }
      sx={{ ml: "0.4rem", ..._sx }}
    >
      {isRecording ? "入力を終了" : "音声を入力"}
    </ButtonWithAnalytics>
  );
};

RecordButton.propTypes = {
  sx: PropTypes.object,
  isRecording: PropTypes.any,
  messageInput: PropTypes.any,
  isStartingRecord: PropTypes.any,
  handleClickRecordButton: PropTypes.any,
};
