import {
  Modal,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { ButtonWithAnalytics } from "./ComponentWithAnalytics";
import PropTypes from "prop-types";
import WarningIcon from "@mui/icons-material/Warning";
import { useState, useEffect, forwardRef } from "react";

export const SystemAudioNotification = ({
  isOpen,
  setIsOpen,
  onClose,
  isSettingCancel = false,
  isSettingFailure = false,
}) => {
  const [checked, setChecked] = useState(() => {
    return (
      JSON.parse(localStorage.getItem("system-audio-notification-hide")) ||
      false
    );
  });

  const commonModalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "0.25rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const FirstExplanationAndFailure = forwardRef(() => {
    return (
      <Box
        id="notification-modal-box"
        sx={{
          ...commonModalStyles,
          width: {
            xs: 320,
            md: 600,
          },
          height: {
            xs: 540,
            md: 580,
          },
          p: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: {
              xs: 360,
              md: 570,
            },
            marginTop: -2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: 3,
            }}
          >
            <WarningIcon
              sx={{
                color: "#E8415B",
                marginRight: 2,
                fontSize: 30,
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {isSettingFailure && (
                <Typography
                  variant="h8"
                  align="center"
                  sx={{ marginBottom: 0 }}
                >
                  オンライン会議の音声を入力する設定に不備があります
                  <br />
                  設定内容を再度ご確認ください
                </Typography>
              )}
              {!isSettingFailure && (
                <Typography
                  variant="h8"
                  align="center"
                  sx={{ marginBottom: 0 }}
                >
                  オンライン会議の音声を入力する場合は、画面を選択後、
                  <br />
                  <strong>システムオーディオを「ON」に設定</strong>してください
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#ECF3F7",
              width: "95%",
              height: "70%",
            }}
          >
            {isSettingFailure && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: "0.75rem",
                  mt: 1,
                  mb: 3,
                  color: "#E8415B",
                }}
              >
                ・音声を共有したいオンライン会議の画面を正しく表示できていますか？
                <br />
                ・システム音声の共有を「ON」に設定していますか？
              </Typography>
            )}
            <img
              src={`${process.env.PUBLIC_URL}/system_audio_setting.png`}
              alt="contents"
              style={{
                width: "65%",
                height: "auto",
              }}
            />
            {!isSettingFailure && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: "0.75rem",
                  mt: 5,
                  color: "#E8415B",
                }}
              >
                ※システムオーディオの共有が正しく設定していない場合、録音できない可能性があります。
              </Typography>
            )}
          </Box>
          {!isSettingFailure && (
            <FormControlLabel
              sx={{
                mt: 2,
              }}
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                  sx={{
                    fontSize: "0.75rem",
                    color: "gray",
                  }}
                />
              }
              label={<Typography>今後この画面を表示しない</Typography>}
            />
          )}
          <Box display="flex" justifyContent="space-between" mt={1}>
            <ButtonWithAnalytics
              eventName="clickConfirmSystemAudioNotification"
              color="primary"
              variant="contained"
              onClickFunc={() => {
                handleClose();
              }}
              sx={{
                width: "8.5rem",
                height: "2.8rem",
                mt: isSettingFailure ? "1rem" : "0",
              }}
            >
              確認しました
            </ButtonWithAnalytics>
          </Box>
        </Box>
      </Box>
    );
  });
  FirstExplanationAndFailure.displayName = "FirstExplanationAndFailure";

  const SelectedCancelModal = forwardRef(() => {
    return (
      <Box
        sx={{
          ...commonModalStyles,
          alignItems: "flex-start",
          boxShadow: 24,
          width: {
            xs: 320,
            md: 420,
          },
          height: {
            xs: 400,
            md: 180,
          },
          padding: 4,
        }}
      >
        <Box>
          <Typography variant="h6" sx={{ mb: 2, ml: 2 }}>
            音声の入力をキャンセル
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, ml: 2 }}>
            音声の入力をキャンセルして入力画面に戻ります。
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            width: "100%",
            height: "100%",
          }}
        >
          <ButtonWithAnalytics
            eventName="clickReturnToAudioShareSetting"
            onClickFunc={handleClose}
          >
            共有の設定に戻る
          </ButtonWithAnalytics>
          <ButtonWithAnalytics
            eventName="clickCancelAudioShareSetting"
            onClickFunc={handleExit}
          >
            了解
          </ButtonWithAnalytics>
        </Box>
      </Box>
    );
  });
  SelectedCancelModal.displayName = "SelectedCancelModal";

  const handleClose = (_event, reason) => {
    if (reason !== "backdropClick") {
      setIsOpen(false);
      if (onClose) {
        onClose();
      }
    }
  };

  const handleExit = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setChecked(
      JSON.parse(localStorage.getItem("system-audio-notification-hide")),
    );
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "system-audio-notification-hide",
      JSON.stringify(checked),
    );
  }, [checked]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableAutoFocus
      disableRestoreFocus
    >
      {isSettingCancel ? (
        <SelectedCancelModal />
      ) : (
        <FirstExplanationAndFailure />
      )}
    </Modal>
  );
};

SystemAudioNotification.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onClose: PropTypes.func,
  isSettingCancel: PropTypes.bool,
  isSettingFailure: PropTypes.bool,
};
