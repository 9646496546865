import { API, graphqlOperation } from "aws-amplify";
import { getMonthlyVoiceRecognitionUsage } from "../graphql/queries";
import {
  createMonthlyVoiceRecognitionUsage,
  updateMonthlyVoiceRecognitionUsage,
} from "../graphql/mutations";

export default class MonthlyVoiceRecognitionUsageRepository {
  async getMonthlyUsage(userId, year, month) {
    const params = {
      userId,
      year,
      month,
    };
    const monthlyUsage = await API.graphql(
      graphqlOperation(getMonthlyVoiceRecognitionUsage, params),
    );
    return monthlyUsage.data.getMonthlyVoiceRecognitionUsage;
  }

  async createMonthlyVoiceRecognitionUsage(userId, year, month, usageTime) {
    const monthlyUsage = {
      userId,
      year,
      month,
      usageTime,
    };
    return await API.graphql(
      graphqlOperation(createMonthlyVoiceRecognitionUsage, {
        input: monthlyUsage,
      }),
    );
  }

  async updateMonthlyVoiceRecognitionUsage(userId, year, month, usageTime) {
    const monthlyUsage = {
      userId,
      year,
      month,
      usageTime,
    };
    return await API.graphql(
      graphqlOperation(updateMonthlyVoiceRecognitionUsage, {
        input: monthlyUsage,
      }),
    );
  }
}
