import TenantPlanSettingsRepository from "repositories/TenantPlanSettings";
import { createContext, useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import PropTypes from "prop-types";

export const TenantPlanContext = createContext({
  tenantPlan: {},
});

const tenantPlanSettingsRepository = new TenantPlanSettingsRepository();

export const TenantPlanProvider = ({ children }) => {
  const [tenantPlan, setTenantPlan] = useState("");

  const updateMessage = async () => {
    try {
      const options = await tenantPlanSettingsRepository.getPlanMasters();
      setTenantPlan(options);
    } catch (error) {
      console.error("Error updating custom message:", error);
      setTenantPlan("");
    }
  };

  useEffect(() => {
    const checkAuthState = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        updateMessage(); // ユーザーが認証されている場合の処理
      } catch (error) {
        // ユーザーが認証されていない場合などこのような分岐はAWS Amplify側の仕様
      }
    };
    const listener = (data) => {
      switch (data.payload.event) {
        case "signIn":
          updateMessage(); // ログイン時に updateMessage を呼び出す
          break;
        default:
          break;
      }
    };
    checkAuthState();
    Hub.listen("auth", listener);

    // クリーンアップ関数
    return () => Hub.remove("auth", listener);
  }, []);

  return (
    <TenantPlanContext.Provider value={{ tenantPlan }}>
      {children}
    </TenantPlanContext.Provider>
  );
};
TenantPlanProvider.propTypes = {
  children: PropTypes.node,
};
