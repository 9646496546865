import { useState, useEffect, useContext, createContext } from "react";
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ButtonWithAnalytics } from "./ComponentWithAnalytics";
import Checkbox from "@mui/material/Checkbox";
import { useAuthenticator } from "@aws-amplify/ui-react";
import PropTypes from "prop-types";
import { TenantPlanContext } from "./TenantPlanProvider";

const disableNotification = false; // お知らせ機能を無効化したい場合trueにする。
const featureName = "v0.29.0"; // 機能の名前。お知らせ画像のディレクトリ名も兼ねる。
const title = "お知らせ"; // お知らせのタイトル
const isNotificationDiffBetweenPlans = false; // 新旧プランでお知らせ内容が違う場合trueにする。
const isUpdatedServiceTerms = true; // 利用規約の変更があった場合trueにする。
const isUpdatedPrivacyPolicy = true; // プライバシーポリシーの変更があった場合trueにする。
const isUpdatedDataPolicy = true; // データポリシーの変更があった場合trueにする。
const isNeedToAcceptTerms = () => {
  return isUpdatedServiceTerms || isUpdatedPrivacyPolicy || isUpdatedDataPolicy;
};

export const FeatureNotificationContext = createContext("feature-notification");

export default function WithFeatureNotificationModal({ children }) {
  const theme = useTheme();
  const isPcView = useMediaQuery(theme.breakpoints.up("md"));
  const { tenantPlan } = useContext(TenantPlanContext);

  const { route } = useAuthenticator((context) => [context.route]);
  const viewSettingKey = `feature-notification-view-setting-${featureName}`;
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const [isDoneCheckViewSetting, setIsDoneCheckViewSetting] = useState(false);
  const handleCloseModal = (event, reason) => {
    if (reason !== "backdropClick") {
      handleClose;
    }
  };
  const handleClose = () => {
    if (hide) {
      localStorage.setItem(viewSettingKey, "hide");
    }
    setOpen(false);
  };
  const handleHideChange = (event) => {
    setHide(event.target.checked);
  };
  const handleAcceptChange = (event) => {
    setIsAccepted(event.target.checked);
  };

  const getFeatureNotificationImagePath = (dirNameByPlan) => {
    return isPcView
      ? `${process.env.PUBLIC_URL}/feature_notification/${featureName}${dirNameByPlan}/pc.png`
      : `${process.env.PUBLIC_URL}/feature_notification/${featureName}${dirNameByPlan}/sp.png`;
  };
  const imagePath = () => {
    if (!isNotificationDiffBetweenPlans) {
      // 新旧プランでお知らせ内容が同一の場合
      return getFeatureNotificationImagePath("");
    } else {
      // 新旧プランでお知らせ内容が異なる場合
      if (tenantPlan?.isProcessedTextBasedPlan == undefined) {
        return null; // tenantPlanがロードされるまで一旦nullを返す
      } else if (tenantPlan?.isProcessedTextBasedPlan == true) {
        return getFeatureNotificationImagePath("/processedTextBasedPlan");
      } else {
        return getFeatureNotificationImagePath("/userNumberBasedPlan");
      }
    }
  };

  useEffect(() => {
    // ログインをした直後のみお知らせを表示する。（ログイン済みの状態でアプリを開いた場合やリロードした場合も含める。）
    if (route === "authenticated") {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        window.alert(
          "アプリのバージョンが更新されています。\n再度アプリをホーム画面に追加しなおしてください。\n文字起こし中に画面がロックされる事象が解消されます。",
        );
      }

      setOpen(
        !disableNotification && localStorage.getItem(viewSettingKey) !== "hide",
      );
      setIsAccepted(false);
      setIsDoneCheckViewSetting(true);
    }
  }, [route]);

  return (
    <FeatureNotificationContext.Provider
      value={{ open, isDoneCheckViewSetting }}
    >
      {children}
      <Modal
        id="feature-notification-modal"
        open={open}
        onClose={isNeedToAcceptTerms() ? handleCloseModal : handleClose}
        disableAutoFocus
        disableRestoreFocus
      >
        <Box
          id="feature-notification-modal-box"
          sx={{
            position: "absolute",
            top: {
              xs: "50%",
              md: "50%",
            },

            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            width: {
              xs: 320,
              md: 830,
            },
            height: {
              xs: 540,
              md: 700,
            },
            p: 4,
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            sx={{
              fontSize: isNeedToAcceptTerms() ? "1.0rem" : "inheret",
            }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: {
                xs: 360,
                md: 480,
              },
            }}
          >
            <img src={imagePath()} alt="お知らせ" />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checked={hide}
                  onChange={handleHideChange}
                  inputProps={{ "aria-label": "hide checkbox" }}
                />
                <Typography variant="body2">
                  今後このお知らせを表示しない
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  pb: isPcView ? theme.spacing(4) : 0,
                }}
              >
                <Checkbox
                  id="checkbox-accept-terms"
                  checked={isAccepted}
                  onChange={handleAcceptChange}
                  inputProps={{ "aria-label": "hide checkbox" }}
                />
                <Typography variant="body2">
                  {isUpdatedServiceTerms && (
                    <>
                      <Link
                        href="/terms_of_service.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        利用規約
                      </Link>
                      {" / "}
                    </>
                  )}
                  {isUpdatedPrivacyPolicy && (
                    <>
                      <Link
                        href="/privacy_policy.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        プライバシーポリシー
                      </Link>
                      {" / "}
                    </>
                  )}
                  {isUpdatedDataPolicy && (
                    <>
                      <Link
                        href="/data_policy.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        データポリシー
                      </Link>
                    </>
                  )}
                  に同意する
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <ButtonWithAnalytics
                eventName="clickFeatureNotificationClose"
                sx={{ width: "10rem" }}
                variant="contained"
                onClickFunc={handleClose}
                disabled={!isAccepted}
                id="button-modal-close"
              >
                閉じる
              </ButtonWithAnalytics>
            </Box>
          </Box>
        </Box>
      </Modal>
    </FeatureNotificationContext.Provider>
  );
}

WithFeatureNotificationModal.propTypes = {
  children: PropTypes.node.isRequired,
};
