/**
 * Scrolls the element with the ID "main" to the position of the provided element, with an optional offset.
 *
 * @param {HTMLElement} element - The element to scroll to.
 * @param {number} [offsetX=0] - The X offset to apply to the scroll position. Positive values move the scroll position to the right, negative values to the left.
 * @param {number} [offsetY=0] - The Y offset to apply to the scroll position. Positive values move the scroll position down, negative values up.
 * @returns {void}
 */
export function scrollMUIMainComponent(element, offsetX = 0, offsetY = 0) {
  // Check if the element exists
  if (!element) {
    console.error("No element provided");
    return;
  }

  // Get the element to be scrolled
  const scrolledElement = document.getElementById("main");
  if (!scrolledElement) {
    console.error("Scrolled element not found: main");
    return;
  }

  // Get the position of the element
  var rect = element.getBoundingClientRect();

  // Get the current scroll position of the scrolled element
  const currentScrollTop = scrolledElement.scrollTop;
  const currentScrollLeft = scrolledElement.scrollLeft;

  // Calculate the position to scroll to (the difference between the element's position and the current scroll position, plus the offset)
  const top = rect.top - currentScrollTop + offsetY;
  const left = rect.left - currentScrollLeft + offsetX;

  // Scroll to the calculated position
  scrolledElement.scroll({
    top: top,
    left: left,
    behavior: "smooth",
  });
}

export const scrollTextareaToCaret = (element) => {
  if (!element) {
    console.error("No element provided");
    return;
  }
  element.blur();
  element.focus();
};
