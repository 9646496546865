// components/Login.js
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import { useNavigate, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Checkbox } from "../../components/TermCheckbox";
import { Box } from "@mui/material";
import "../../App.css";
import TextWithHereLink from "./TextWithHereLink";
import { useTheme } from "@mui/material/styles";
import { SetUIVocabularies } from "./UIVocabularies";
import CopyrightText from "../../components/CopyrightText";

const tenant_id_fieldname = "custom:tenant_id";
// envから取得した値をboolean型に変換
const isExhibitionTrial = process.env.REACT_APP_IS_EXHIBITION_TRIAL === "true";

const LoginHeader = () => {
  useTheme();
  return (
    <Box textAlign="center" className="logo">
      <img
        src={`${process.env.PUBLIC_URL}/logo.svg`}
        style={{ width: "14rem" }}
        draggable="false"
      />
    </Box>
  );
};

const components = {
  SignIn: {
    Header() {
      return <LoginHeader />;
    },
    Footer() {
      const { toResetPassword, toSignUp } = useAuthenticator();
      const theme = useTheme();

      return (
        <View textAlign="center">
          <Box sx={{ mb: theme.spacing(7) }}>
            <TextWithHereLink
              text="パスワードを忘れた方は"
              onClick={toResetPassword}
            />
          </Box>
          <Box sx={{ mb: theme.spacing(7) }}>
            <TextWithHereLink text="新規アカウント作成は" onClick={toSignUp} />
          </Box>
          <Box>
            <TextWithHereLink
              text="ご利用マニュアルは"
              onClick={() => window.open("/manual.pdf", "_blank")}
            />
          </Box>
        </View>
      );
    },
  },
  SignUp: {
    Header() {
      return <LoginHeader />;
    },
    FormFields() {
      const { validationErrors } = useAuthenticator();

      return (
        <>
          <Authenticator.SignUp.FormFields />
          <Checkbox
            validationErrors={validationErrors}
            isExhibitionTrial={isExhibitionTrial}
          />
        </>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();
      const theme = useTheme();

      return (
        <View textAlign="center">
          <Box sx={{ mt: theme.spacing(5) }}>
            <TextWithHereLink text="サインインは" onClick={toSignIn} />
          </Box>
        </View>
      );
    },
  },
  ResetPassword: {
    Header() {
      return <LoginHeader />;
    },
  },

  Footer() {
    const theme = useTheme();

    return (
      <View className="custom-footer">
        <CopyrightText sx={{ paddingBottom: theme.spacing(5) }} />
      </View>
    );
  },
};

export default function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  const [searchParams] = useSearchParams();

  // シングルテナントでのサインアップ強制のためのデフォルト値取得
  // amplifyの環境設定にREACT_APP_FORCE_SIGN_UP_URL_TENANT_IDを設定
  // noneの場合はURLのtenantパラメータを使用
  // none以外の場合はその値をデフォルト値として使用
  const getSignUpDefaultValue = () => {
    const tenantId = process.env.REACT_APP_FORCE_SIGN_UP_URL_TENANT_ID;
    if (tenantId && tenantId !== "none") {
      return tenantId;
    }
    return searchParams.get("tenant");
  };

  const formFields = {
    signUp: {
      "custom:tenant_id": {
        label: "組織ID:",
        placeholder: "マクニカ営業/管理者にお問い合わせください:",
        isRequired: true,
        order: 1,
        defaultValue: getSignUpDefaultValue(),
      },
      email: {
        order: 2,
      },
      password: {
        order: 3,
      },
      confirm_password: {
        order: 4,
      },
    },
  };
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  SetUIVocabularies("ja");

  return (
    <View id="auth-wrapper-custom">
      <Authenticator
        initialState="signIn"
        components={components}
        formFields={formFields}
        services={{
          async validateCustomSignUp(formData) {
            if (!formData.acknowledgement) {
              return {
                acknowledgement: `${
                  isExhibitionTrial ? "おまとめ忍者トライアル特約、" : ""
                }利用規約、プライバシーポリシー、データポリシーに同意が必要です`,
              };
            }
          },
          async handleSignUp(formData) {
            let { username, password } = formData;
            return Auth.signUp({
              username,
              password,
              attributes: {
                "custom:tenant_id": formData.attributes[tenant_id_fieldname],
              },
              autoSignIn: {
                enabled: true,
              },
            });
          },
        }}
      />
    </View>
  );
}
