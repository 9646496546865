import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";

export const SetUIVocabularies = (lng) => {
  I18n.putVocabularies(translations);
  I18n.setLanguage(lng);
  I18n.putVocabulariesForLanguage("ja", {
    // label
    "Enter your email": "メールアドレス",
    "Code *": "コード",
    "Confirm Password": "パスワード再入力",
    "Create Account": "アカウント作成",
    // message
    "User does not exist.": "ユーザーが存在しません",
    "Incorrect username or password.": "ユーザー名またはパスワードが違います",
    "User is not confirmed.": "ユーザーは検証されていません",
    "User already exists": "ユーザーは既に存在します",
    "Invalid verification code provided, please try again.":
      "指定された確認コードが無効です。もう一度お試しください",
    "Invalid password format": "パスワードのフォーマットが不正です",
    "An account with the given email already exists.":
      "そのメールアドレスは既に存在します",
    "Password must have at least 8 characters": "パスワードは8文字以上です",
    "Invalid code received for user": "無効なコードです",
    "Your passwords must match": "パスワードが一致しません",
    "Username/client id combination not found.": "アカウントが存在しません",
    "Attempt limit exceeded, please try after some time.":
      "試行回数の上限を超えました。しばらくしてから再試行してください。",
    "Network error": "ネットワークエラーが発生しました",
  });
};
