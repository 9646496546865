import PropTypes from "prop-types";
import { ButtonWithAnalytics } from "./ComponentWithAnalytics";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, Typography } from "@mui/material";
import TranscriptsList from "./TranscriptsList";

export default function TranscriptsPerLabelDialog(props) {
  const { label, message, open, setOpen } = props;

  const extractTranscriptPerLabel = (label) => {
    const regex = new RegExp(label + ":\n.{1,50}", "gs");
    let transcript = message.match(regex);
    if (transcript === null) {
      return [];
    }
    return transcript;
  };
  const transcript = extractTranscriptPerLabel(label);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{label}の発言</DialogTitle>
      <DialogContent>
        {message.includes(label) && transcript.length > 0 ? (
          <>
            <Typography variant="body1">
              {label}の発言を全件表示しています。
            </Typography>
            <TranscriptsList label={label} transcript={transcript} />
          </>
        ) : (
          <>
            <Typography variant="body1">{label}の発言はありません。</Typography>
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          padding: "0.875rem",
        }}
      >
        <ButtonWithAnalytics
          eventName="clickTranscriptsPerLabelDialogClose"
          sx={{ width: "10rem" }}
          variant="contained"
          onClickFunc={handleClose}
          id="button-transcript-dialog-close"
        >
          閉じる
        </ButtonWithAnalytics>
      </DialogActions>
    </Dialog>
  );
}

TranscriptsPerLabelDialog.propTypes = {
  label: PropTypes.string,
  message: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
