import React from "react";
import PropTypes from "prop-types";
import { limitStatus } from "hooks/useUsageSituation";
import { Box, Typography } from "@mui/material";

const UsageLimitWarnig = ({ usageLimitStatus }) => {
  const statusMessage = {
    exceeded:
      "処理文字数が上限に到達しました。新規の処理を実行するためには管理者にお問い合わせください。",
    closeToLimit:
      "処理文字数が上限に到達しそうです。上限に達した場合は、新規の処理が実行できない場合があります。",
  };

  if (usageLimitStatus === limitStatus.safe || usageLimitStatus === null) {
    return null;
  }

  return (
    <Box
      borderRadius={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#FF0000",
        p: 2,
        mt: 2,
        m: 1,
        border: 1,
      }}
    >
      <Typography variant="subtitle2">
        {statusMessage[usageLimitStatus] || ""}
      </Typography>
    </Box>
  );
};

UsageLimitWarnig.propTypes = {
  usageLimitStatus: PropTypes.string,
};

export default UsageLimitWarnig;
