export async function copyTextToClipboard(text) {
  try {
    // Clipboard APIを利用できるかチェック
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      console.log("Text copied to clipboard");
    } else {
      // Clipboard APIが利用できない場合は、従来のtextareaを利用した方法でコピー
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      console.log("Text copied to clipboard (fallback)");
    }
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
}
