import { Typography, Link, Box } from "@mui/material";

export default function CopyrightText(props) {
  return (
    <Box {...props}>
      <Typography variant="subtitle2" color="text.secondary" align="center">
        <Link
          color="inherit"
          href="https://www.macnica.co.jp/business/ai/manufacturers/realize/"
          underline="none"
        >
          おまとめ忍者 by Macnica.ai
        </Link>
      </Typography>
    </Box>
  );
}
