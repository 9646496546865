import { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

export const DragAudioFileZone = ({ onChange, isUploading, setIsDragFile }) => {
  const [dragging, setDragging] = useState(false);
  const refFileElement = useRef(null);
  const allowEtentions = ".(mp3|wav)$";
  const { enqueueSnackbar } = useSnackbar();

  const varidateFile = (file) => {
    if (!file.name.match(allowEtentions)) {
      enqueueSnackbar("ファイル形式は.mp3または、.wavのみ対応しています。", {
        variant: "error",
      });
      return false;
    }

    if (file.size > 2147483648) {
      enqueueSnackbar("ファイルサイズは2GB以下にしてください。", {
        variant: "error",
      });
      return false;
    }

    return true;
  };

  const handleDrop = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();

      if (e.dataTransfer.files?.length > 0 && refFileElement.current) {
        if (!varidateFile(e.dataTransfer.files[0])) {
          setDragging(false);
          setIsDragFile(false);
          return;
        }
        const dt = new DataTransfer();
        dt.items.add(e.dataTransfer.files[0]);
        refFileElement.current.files = dt.files;

        onChange(refFileElement.current.files);
      }
      setDragging(false);
      setIsDragFile(true);
    },
    [onChange],
  );

  const handleChange = useCallback(
    async (e) => {
      if (e.target.files) {
        onChange(e.target.files);
      }

      if (e.target.files.length > 0) {
        setIsDragFile(true);
      }
    },
    [onChange],
  );

  const handleDragoverOrLeave = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setDragging(e.type === "dragover");
    setIsDragFile(e.type === "dragover");
  }, []);

  return (
    <Box
      id="dragAudioFileZone"
      sx={{
        border: "3px dashed #999",
        height: "15rem",
        width: "60rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: dragging ? "#eee" : "#ddd",
        flexDirection: "column",
      }}
      onDragOver={handleDragoverOrLeave}
      onDragLeave={handleDragoverOrLeave}
      onDrop={handleDrop}
    >
      {isUploading ? (
        <Box>
          <CircularProgress />
          <Typography variant="subtitle2" align="left">
            ファイルをアップロード中です。しばらくお待ちください...
          </Typography>
        </Box>
      ) : (
        <Box>
          ここにファイルをドロップ
          <br />
          または
          <br />
          <input
            ref={refFileElement}
            onChange={handleChange}
            type="file"
            multiple={false}
            accept="audio/mp3,audio/wav"
          />
        </Box>
      )}
    </Box>
  );
};

DragAudioFileZone.propTypes = {
  onChange: PropTypes.func.isRequired,
  isUploading: PropTypes.bool,
  setIsDragFile: PropTypes.func,
};
