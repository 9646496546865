import ProperNounListHeader from "./ProperNounListHeader";
import {
  Box,
  List,
  ListItem,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ProperNounsSettingRepository from "../../repositories/ProperNounsSettingRepository";
import useWordRegister from "../../hooks/useWordRegister";
import { useState } from "react";
import { properNounCategories } from "constants/properNounCategories";
import useProperNounsSettings from "hooks/useProperNounsSettings";

export default function WordRegistration() {
  const PERSONAL_PROPER_NOUN = properNounCategories.personal;
  const theme = useTheme();
  const navigate = useNavigate();
  const properNounsSettings = ProperNounsSettingRepository.loadSettings();
  const { loadCorporateProperNounsCategories } = useWordRegister();
  const [
    corporateProperNounsCategoryList,
    setCorporateProperNounsCategoryList,
  ] = useState([]);
  const { setInitialProperNounsSettings } = useProperNounsSettings();

  const moveToProperNounList = (category) => {
    if (category.id === PERSONAL_PROPER_NOUN.id) {
      navigate({
        pathname: "/word-registration",
      });
      return;
    } else {
      navigate(
        {
          pathname: "/corporate-words",
          search: `?categoryId=${category.id}`,
        },
        { state: { displayName: category.name } },
      );
      return;
    }
  };

  useEffect(() => {
    setInitialProperNounsSettings();
    const getCategoryList = async () => {
      let uniqueCategories = [];
      const response = await loadCorporateProperNounsCategories();
      if (response && response.length > 0) {
        uniqueCategories = Array.from(
          response.map((category) => ({
            id: category.id,
            name: category.name,
          })),
        );
      }
      uniqueCategories.unshift(PERSONAL_PROPER_NOUN);
      setCorporateProperNounsCategoryList(uniqueCategories);
    };
    getCategoryList();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        mt: { xs: theme.spacing(5), md: theme.spacing(10) },
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          width: { md: "640px" },
          marginX: { md: "auto" },
        }}
      >
        <ProperNounListHeader
          title="辞書の管理"
          message="パブリック辞書と同じ表記の登録は、プライベート辞書が優先されます"
        />
        <Grid item>
          <List sx={{ padding: "0" }}>
            {corporateProperNounsCategoryList.map((category, index) => (
              <ListItem
                id={`personalRegisteredWordsItem_${index}`}
                key={index}
                divider
                sx={{
                  padding: "0.875rem 0.75rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => moveToProperNounList(category)}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "inherit",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      width: { xs: "157.5px", md: "300px" },
                      whiteSpace: "nowrap",
                      lineHeight: "1rem",
                    }}
                  >
                    {category.name}
                  </Typography>
                </Box>
                {properNounsSettings[category.id]?.isValid && (
                  <CheckIcon sx={{ color: "#2272ce" }}></CheckIcon>
                )}
                <IconButton
                  aria-label="edit"
                  sx={{ p: 0, pl: theme.spacing(1) }}
                >
                  <ArrowForwardIosIcon sx={{ width: "14px" }} />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Box>
    </Box>
  );
}
