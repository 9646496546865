import TenantOptionSettingsRepository from "../repositories/TenantOptionSettings";
import { createContext, useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import PropTypes from "prop-types";
export const TenantOptionsContext = createContext({
  tenantOptions: {},
});

const tenantOptionSettingsRepository = new TenantOptionSettingsRepository();

export const TenantOptionsProvider = ({ children }) => {
  const [tenantOptions, setTenantOptions] = useState("");

  const updateMessage = async () => {
    try {
      const options =
        await tenantOptionSettingsRepository.getTenantOptionSettings();
      setTenantOptions(options);
    } catch (error) {
      console.error("Error updating custom message:", error);
      setTenantOptions("");
    }
  };

  useEffect(() => {
    const checkAuthState = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        updateMessage(); // ユーザーが認証されている場合の処理
      } catch (error) {
        // ユーザーが認証されていない場合などこのような分岐はAWS Amplify側の仕様
      }
    };
    const listener = (data) => {
      switch (data.payload.event) {
        case "signIn":
          updateMessage(); // ログイン時に updateMessage を呼び出す
          break;
        default:
          break;
      }
    };
    checkAuthState();
    Hub.listen("auth", listener);

    // クリーンアップ関数
    return () => Hub.remove("auth", listener);
  }, []);

  return (
    <TenantOptionsContext.Provider value={{ tenantOptions }}>
      {children}
    </TenantOptionsContext.Provider>
  );
};
TenantOptionsProvider.propTypes = {
  children: PropTypes.node,
};
