import { API } from "aws-amplify";
import { listMonthlyTenantProcessedTextCounts } from "../graphql/queries";

export default class TenantPlanSettingsRepository {
  async getPlanMasters() {
    try {
      // テナント毎のデータを取得するには、listMonthlyTenantProcessedTextCountsで取ってくる必要があるため、
      // listPlanMasterは使用せず、listMonthlyTenantProcessedTextCountsを使用している
      const result = await API.graphql({
        query: listMonthlyTenantProcessedTextCounts,
      });

      // Check the existence of data
      if (
        result.data &&
        result.data.listMonthlyTenantProcessedTextCounts &&
        result.data.listMonthlyTenantProcessedTextCounts.items.length > 0 &&
        result.data.listMonthlyTenantProcessedTextCounts.items[0].planId
      ) {
        // Return the first element
        return result.data.listMonthlyTenantProcessedTextCounts.items[0].planId;
      } else {
        // Processing when appropriate data is not found
        console.error("No plan master found.");
        return null; // or appropriate default value
      }
    } catch (error) {
      // Error handling
      console.error("Error fetching plan master:", error);
      throw error; // Re-throw the error or perform appropriate error handling
    }
  }
}
