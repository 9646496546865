import React from "react";
import { RequireAuth } from "./RequireAuth";
import AppContentWrapper from "../components/AppContentWrapper";
import PropTypes from "prop-types";
import { useMessageState } from "hooks/useMessageState";

export function ViewWithAuth({ children }) {
  const {
    messageInput,
    messageOutput,
    handleMessageInputChange,
    handleMessageOutputChange,
  } = useMessageState();

  const propsToChildren = {
    messageInput,
    messageOutput,
    handleMessageInputChange,
    handleMessageOutputChange,
  };

  // Clone children and pass props to them
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, propsToChildren);
    }
    return child;
  });

  return (
    <RequireAuth>
      <AppContentWrapper
        handleMessageInputChange={handleMessageInputChange}
        handleMessageOutputChange={handleMessageOutputChange}
      >
        {childrenWithProps}
      </AppContentWrapper>
    </RequireAuth>
  );
}

ViewWithAuth.propTypes = {
  children: PropTypes.node,
};
