import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import IosShareIcon from "@mui/icons-material/IosShare";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { isIphone } from "../../../helper/deviceHelper";
import AnalyticsWithTenant from "../../../helper/AnalyticsWithTenant";
import {
  jstNow,
  formatDateTimeYYYYMMDD_HHmmss,
} from "../../../helper/dateTimeHelper";
import { downloadText } from "../../../helper/fileHelper";

import { ButtonWithAnalytics } from "../../../components/ComponentWithAnalytics";

export default function DownloadOrShareButton(props) {
  const { getContentBySummaryMode, enqueueSnackbar, messageOutput, loading } =
    props;

  const shareContent = async () => {
    try {
      await navigator.share(getContentBySummaryMode());
      AnalyticsWithTenant.record({
        name: "shareContent",
      });
    } catch (e) {
      console.error(e);
      let message = "エラーが発生しました。";
      if (e.name == "AbortError") {
        message = "共有がキャンセルされました。";
        enqueueSnackbar(message);
      } else if (e.cause && e.cause.code === "invalidSummaryMode") {
        message = "まとめモードが不正です。";
        enqueueSnackbar(message, { variant: "error" });
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  const downloadContent = async (filename, content) => {
    try {
      await downloadText(filename, content);
      AnalyticsWithTenant.record({
        name: "startDownloadContent",
      });
      enqueueSnackbar("入出力内容のダウンロードを開始しました。", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        `入出力内容のダウンロード開始に失敗しました。: ${error.message}`,
        {
          variant: "error",
        },
      );
    }
  };

  const handleShareContent = async () => {
    await shareContent();
  };

  const handleClickDownload = () => {
    let content = getContentBySummaryMode().text;
    downloadContent(
      `o-ninja_${formatDateTimeYYYYMMDD_HHmmss(jstNow())}.txt`,
      content,
    );
  };

  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <ButtonWithAnalytics
        eventName={isIphone() ? "clickShareContent" : "clickDownloadContent"}
        id="saveContent"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!messageOutput || loading}
        onClickFunc={isIphone() ? handleShareContent : handleClickDownload}
        sx={{
          margin: "0.4rem 0",
          width: { xs: "100%", md: "160px" },
        }}
        startIcon={
          isIphone() ? (
            <IosShareIcon sx={{ width: "15px", margin: "-0.2rem" }} />
          ) : (
            <DownloadIcon sx={{ width: "15px", margin: "-0.2rem" }} />
          )
        }
      >
        {isIphone() ? "共有" : "ダウンロード"}
      </ButtonWithAnalytics>
    </Stack>
  );
}

DownloadOrShareButton.propTypes = {
  getContentBySummaryMode: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  messageOutput: PropTypes.string,
  loading: PropTypes.bool,
};
