import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ButtonWithAnalytics } from "../components/ComponentWithAnalytics";
import PropTypes from "prop-types";

export default function OverwriteConfirmationDialog(props) {
  const {
    openStatus,
    setOpenStatus,
    handleReadTextFromFile,
    setDragging,
    file,
  } = props;

  const closeOverwriteConfirmed = () => {
    handleReadTextFromFile(file);
    setOpenStatus(false);
  };

  const closeOverwriteNotConfirmed = () => {
    setDragging(false);
    setOpenStatus(false);
  };

  return (
    <Dialog
      open={openStatus}
      onClose={closeOverwriteNotConfirmed}
      aria-labelledby="input-overwrite-alert-dialog-title"
      aria-describedby="input-overwrite-alert-dialog-description"
    >
      <DialogTitle id="input-overwrite-alert-dialog-title">
        {"上書きしてよろしいですか？"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="input-overwrite-alert-dialog-description">
          テキストエリアに入力されている文字を上書きします。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonWithAnalytics
          eventName="clickCancelOverwriteInput"
          onClickFunc={closeOverwriteNotConfirmed}
        >
          キャンセル
        </ButtonWithAnalytics>
        <ButtonWithAnalytics
          eventName="clickOverwriteInput"
          onClickFunc={closeOverwriteConfirmed}
          autoFocus
        >
          上書きする
        </ButtonWithAnalytics>
      </DialogActions>
    </Dialog>
  );
}

OverwriteConfirmationDialog.propTypes = {
  openStatus: PropTypes.bool,
  setOpenStatus: PropTypes.func,
  handleReadTextFromFile: PropTypes.func,
  setDragging: PropTypes.func,
  file: PropTypes.object,
};
