import { Box, ListItem, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTheme } from "@mui/material/styles";

const DisplayWords = ({ personalRegisteredWords, moveToEdit }) => {
  const theme = useTheme();

  return (
    <>
      {personalRegisteredWords.map((noun, index) => (
        <ListItem
          id={`personalRegisteredWordsItem_${index}`}
          key={index}
          divider
          sx={{
            padding: "0.875rem 0.75rem",
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={() => moveToEdit(index, noun.word, noun.reading)}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "inherit",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: { xs: "157.5px", md: "300px" },
                whiteSpace: "nowrap",
                lineHeight: "1rem",
              }}
            >
              {noun.word}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "var(--monotone-color-set-m-500, #B7B7B7)",
                textAlign: "right",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: { xs: "157.5px", md: "300px" },
                whiteSpace: "nowrap",
                lineHeight: "1rem",
              }}
            >
              {noun.reading}
            </Typography>
          </Box>

          <IconButton aria-label="edit" sx={{ p: 0, pl: theme.spacing(1) }}>
            <ArrowForwardIosIcon sx={{ width: "14px" }} />
          </IconButton>
        </ListItem>
      ))}
    </>
  );
};

export default DisplayWords;

DisplayWords.propTypes = {
  personalRegisteredWords: PropTypes.array,
  moveToEdit: PropTypes.func,
};
