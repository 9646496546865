/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      message
      response
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      message
      response
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      message
      response
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPlanMaster = /* GraphQL */ `
  mutation CreatePlanMaster(
    $input: CreatePlanMasterInput!
    $condition: ModelPlanMasterConditionInput
  ) {
    createPlanMaster(input: $input, condition: $condition) {
      planId
      planName
      maxSummarizeTextCount
      isProcessedTextBasedPlan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlanMaster = /* GraphQL */ `
  mutation UpdatePlanMaster(
    $input: UpdatePlanMasterInput!
    $condition: ModelPlanMasterConditionInput
  ) {
    updatePlanMaster(input: $input, condition: $condition) {
      planId
      planName
      maxSummarizeTextCount
      isProcessedTextBasedPlan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlanMaster = /* GraphQL */ `
  mutation DeletePlanMaster(
    $input: DeletePlanMasterInput!
    $condition: ModelPlanMasterConditionInput
  ) {
    deletePlanMaster(input: $input, condition: $condition) {
      planId
      planName
      maxSummarizeTextCount
      isProcessedTextBasedPlan
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTenantUnderContractPlan = /* GraphQL */ `
  mutation CreateTenantUnderContractPlan(
    $input: CreateTenantUnderContractPlanInput!
    $condition: ModelTenantUnderContractPlanConditionInput
  ) {
    createTenantUnderContractPlan(input: $input, condition: $condition) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        isProcessedTextBasedPlan
        createdAt
        updatedAt
        __typename
      }
      appliedStatus
      contractStartDate
      contractEndDate
      tenantId
      monthlyTextCountResetDate
      createdAt
      updatedAt
      tenantUnderContractPlanPlanIdPlanId
      __typename
    }
  }
`;
export const updateTenantUnderContractPlan = /* GraphQL */ `
  mutation UpdateTenantUnderContractPlan(
    $input: UpdateTenantUnderContractPlanInput!
    $condition: ModelTenantUnderContractPlanConditionInput
  ) {
    updateTenantUnderContractPlan(input: $input, condition: $condition) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        isProcessedTextBasedPlan
        createdAt
        updatedAt
        __typename
      }
      appliedStatus
      contractStartDate
      contractEndDate
      tenantId
      monthlyTextCountResetDate
      createdAt
      updatedAt
      tenantUnderContractPlanPlanIdPlanId
      __typename
    }
  }
`;
export const deleteTenantUnderContractPlan = /* GraphQL */ `
  mutation DeleteTenantUnderContractPlan(
    $input: DeleteTenantUnderContractPlanInput!
    $condition: ModelTenantUnderContractPlanConditionInput
  ) {
    deleteTenantUnderContractPlan(input: $input, condition: $condition) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        isProcessedTextBasedPlan
        createdAt
        updatedAt
        __typename
      }
      appliedStatus
      contractStartDate
      contractEndDate
      tenantId
      monthlyTextCountResetDate
      createdAt
      updatedAt
      tenantUnderContractPlanPlanIdPlanId
      __typename
    }
  }
`;
export const createOptionSettings = /* GraphQL */ `
  mutation CreateOptionSettings(
    $input: CreateOptionSettingsInput!
    $condition: ModelOptionSettingsConditionInput
  ) {
    createOptionSettings(input: $input, condition: $condition) {
      id
      custome_message_enabled
      custome_message_contents
      file_upload_enabled
      multi_language_enabled
      tenant_id
      __typename
    }
  }
`;
export const updateOptionSettings = /* GraphQL */ `
  mutation UpdateOptionSettings(
    $input: UpdateOptionSettingsInput!
    $condition: ModelOptionSettingsConditionInput
  ) {
    updateOptionSettings(input: $input, condition: $condition) {
      id
      custome_message_enabled
      custome_message_contents
      file_upload_enabled
      multi_language_enabled
      tenant_id
      __typename
    }
  }
`;
export const deleteOptionSettings = /* GraphQL */ `
  mutation DeleteOptionSettings(
    $input: DeleteOptionSettingsInput!
    $condition: ModelOptionSettingsConditionInput
  ) {
    deleteOptionSettings(input: $input, condition: $condition) {
      id
      custome_message_enabled
      custome_message_contents
      file_upload_enabled
      multi_language_enabled
      tenant_id
      __typename
    }
  }
`;
export const createAzureOAIEndpointPaths = /* GraphQL */ `
  mutation CreateAzureOAIEndpointPaths(
    $input: CreateAzureOAIEndpointPathsInput!
    $condition: ModelAzureOAIEndpointPathsConditionInput
  ) {
    createAzureOAIEndpointPaths(input: $input, condition: $condition) {
      id
      azureOpenAIEndpoint
      relayRequestAzureApiUrl
      relayRequestAzureApikey
      __typename
    }
  }
`;
export const updateAzureOAIEndpointPaths = /* GraphQL */ `
  mutation UpdateAzureOAIEndpointPaths(
    $input: UpdateAzureOAIEndpointPathsInput!
    $condition: ModelAzureOAIEndpointPathsConditionInput
  ) {
    updateAzureOAIEndpointPaths(input: $input, condition: $condition) {
      id
      azureOpenAIEndpoint
      relayRequestAzureApiUrl
      relayRequestAzureApikey
      __typename
    }
  }
`;
export const deleteAzureOAIEndpointPaths = /* GraphQL */ `
  mutation DeleteAzureOAIEndpointPaths(
    $input: DeleteAzureOAIEndpointPathsInput!
    $condition: ModelAzureOAIEndpointPathsConditionInput
  ) {
    deleteAzureOAIEndpointPaths(input: $input, condition: $condition) {
      id
      azureOpenAIEndpoint
      relayRequestAzureApiUrl
      relayRequestAzureApikey
      __typename
    }
  }
`;
export const createCorporateProperNoun = /* GraphQL */ `
  mutation CreateCorporateProperNoun(
    $input: CreateCorporateProperNounInput!
    $condition: ModelCorporateProperNounConditionInput
  ) {
    createCorporateProperNoun(input: $input, condition: $condition) {
      id
      writing
      reading
      categoryId
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCorporateProperNoun = /* GraphQL */ `
  mutation UpdateCorporateProperNoun(
    $input: UpdateCorporateProperNounInput!
    $condition: ModelCorporateProperNounConditionInput
  ) {
    updateCorporateProperNoun(input: $input, condition: $condition) {
      id
      writing
      reading
      categoryId
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCorporateProperNoun = /* GraphQL */ `
  mutation DeleteCorporateProperNoun(
    $input: DeleteCorporateProperNounInput!
    $condition: ModelCorporateProperNounConditionInput
  ) {
    deleteCorporateProperNoun(input: $input, condition: $condition) {
      id
      writing
      reading
      categoryId
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCorporateProperNounCategory = /* GraphQL */ `
  mutation CreateCorporateProperNounCategory(
    $input: CreateCorporateProperNounCategoryInput!
    $condition: ModelCorporateProperNounCategoryConditionInput
  ) {
    createCorporateProperNounCategory(input: $input, condition: $condition) {
      id
      sortOrder
      name
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCorporateProperNounCategory = /* GraphQL */ `
  mutation UpdateCorporateProperNounCategory(
    $input: UpdateCorporateProperNounCategoryInput!
    $condition: ModelCorporateProperNounCategoryConditionInput
  ) {
    updateCorporateProperNounCategory(input: $input, condition: $condition) {
      id
      sortOrder
      name
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCorporateProperNounCategory = /* GraphQL */ `
  mutation DeleteCorporateProperNounCategory(
    $input: DeleteCorporateProperNounCategoryInput!
    $condition: ModelCorporateProperNounCategoryConditionInput
  ) {
    deleteCorporateProperNounCategory(input: $input, condition: $condition) {
      id
      sortOrder
      name
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTenantSummarizedTextCount = /* GraphQL */ `
  mutation CreateTenantSummarizedTextCount(
    $input: CreateTenantSummarizedTextCountInput!
    $condition: ModelTenantSummarizedTextCountConditionInput
  ) {
    createTenantSummarizedTextCount(input: $input, condition: $condition) {
      summaryStartDateTime
      textCount
      userId
      summaryMode
      inputLang
      outputLang
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTenantSummarizedTextCount = /* GraphQL */ `
  mutation UpdateTenantSummarizedTextCount(
    $input: UpdateTenantSummarizedTextCountInput!
    $condition: ModelTenantSummarizedTextCountConditionInput
  ) {
    updateTenantSummarizedTextCount(input: $input, condition: $condition) {
      summaryStartDateTime
      textCount
      userId
      summaryMode
      inputLang
      outputLang
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTenantSummarizedTextCount = /* GraphQL */ `
  mutation DeleteTenantSummarizedTextCount(
    $input: DeleteTenantSummarizedTextCountInput!
    $condition: ModelTenantSummarizedTextCountConditionInput
  ) {
    deleteTenantSummarizedTextCount(input: $input, condition: $condition) {
      summaryStartDateTime
      textCount
      userId
      summaryMode
      inputLang
      outputLang
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTenantVoiceInputTextCount = /* GraphQL */ `
  mutation CreateTenantVoiceInputTextCount(
    $input: CreateTenantVoiceInputTextCountInput!
    $condition: ModelTenantVoiceInputTextCountConditionInput
  ) {
    createTenantVoiceInputTextCount(input: $input, condition: $condition) {
      voiceInputStartDateTime
      textCount
      inputDuration
      userId
      summaryMode
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTenantVoiceInputTextCount = /* GraphQL */ `
  mutation UpdateTenantVoiceInputTextCount(
    $input: UpdateTenantVoiceInputTextCountInput!
    $condition: ModelTenantVoiceInputTextCountConditionInput
  ) {
    updateTenantVoiceInputTextCount(input: $input, condition: $condition) {
      voiceInputStartDateTime
      textCount
      inputDuration
      userId
      summaryMode
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTenantVoiceInputTextCount = /* GraphQL */ `
  mutation DeleteTenantVoiceInputTextCount(
    $input: DeleteTenantVoiceInputTextCountInput!
    $condition: ModelTenantVoiceInputTextCountConditionInput
  ) {
    deleteTenantVoiceInputTextCount(input: $input, condition: $condition) {
      voiceInputStartDateTime
      textCount
      inputDuration
      userId
      summaryMode
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMonthlyTenantProcessedTextCount = /* GraphQL */ `
  mutation CreateMonthlyTenantProcessedTextCount(
    $input: CreateMonthlyTenantProcessedTextCountInput!
    $condition: ModelMonthlyTenantProcessedTextCountConditionInput
  ) {
    createMonthlyTenantProcessedTextCount(
      input: $input
      condition: $condition
    ) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        isProcessedTextBasedPlan
        createdAt
        updatedAt
        __typename
      }
      activatedStatus
      monthlyTextCount
      monthlyTextCountStartDate
      tenantId
      createdAt
      updatedAt
      monthlyTenantProcessedTextCountPlanIdPlanId
      __typename
    }
  }
`;
export const updateMonthlyTenantProcessedTextCount = /* GraphQL */ `
  mutation UpdateMonthlyTenantProcessedTextCount(
    $input: UpdateMonthlyTenantProcessedTextCountInput!
    $condition: ModelMonthlyTenantProcessedTextCountConditionInput
  ) {
    updateMonthlyTenantProcessedTextCount(
      input: $input
      condition: $condition
    ) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        isProcessedTextBasedPlan
        createdAt
        updatedAt
        __typename
      }
      activatedStatus
      monthlyTextCount
      monthlyTextCountStartDate
      tenantId
      createdAt
      updatedAt
      monthlyTenantProcessedTextCountPlanIdPlanId
      __typename
    }
  }
`;
export const deleteMonthlyTenantProcessedTextCount = /* GraphQL */ `
  mutation DeleteMonthlyTenantProcessedTextCount(
    $input: DeleteMonthlyTenantProcessedTextCountInput!
    $condition: ModelMonthlyTenantProcessedTextCountConditionInput
  ) {
    deleteMonthlyTenantProcessedTextCount(
      input: $input
      condition: $condition
    ) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        isProcessedTextBasedPlan
        createdAt
        updatedAt
        __typename
      }
      activatedStatus
      monthlyTextCount
      monthlyTextCountStartDate
      tenantId
      createdAt
      updatedAt
      monthlyTenantProcessedTextCountPlanIdPlanId
      __typename
    }
  }
`;
export const createMonthlyVoiceRecognitionUsage = /* GraphQL */ `
  mutation CreateMonthlyVoiceRecognitionUsage(
    $input: CreateMonthlyVoiceRecognitionUsageInput!
    $condition: ModelMonthlyVoiceRecognitionUsageConditionInput
  ) {
    createMonthlyVoiceRecognitionUsage(input: $input, condition: $condition) {
      userId
      year
      month
      usageTime
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateMonthlyVoiceRecognitionUsage = /* GraphQL */ `
  mutation UpdateMonthlyVoiceRecognitionUsage(
    $input: UpdateMonthlyVoiceRecognitionUsageInput!
    $condition: ModelMonthlyVoiceRecognitionUsageConditionInput
  ) {
    updateMonthlyVoiceRecognitionUsage(input: $input, condition: $condition) {
      userId
      year
      month
      usageTime
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteMonthlyVoiceRecognitionUsage = /* GraphQL */ `
  mutation DeleteMonthlyVoiceRecognitionUsage(
    $input: DeleteMonthlyVoiceRecognitionUsageInput!
    $condition: ModelMonthlyVoiceRecognitionUsageConditionInput
  ) {
    deleteMonthlyVoiceRecognitionUsage(input: $input, condition: $condition) {
      userId
      year
      month
      usageTime
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createVoiceFileUploadRequest = /* GraphQL */ `
  mutation CreateVoiceFileUploadRequest(
    $input: CreateVoiceFileUploadRequestInput!
    $condition: ModelVoiceFileUploadRequestConditionInput
  ) {
    createVoiceFileUploadRequest(input: $input, condition: $condition) {
      id
      sessionId
      filename
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVoiceFileUploadRequest = /* GraphQL */ `
  mutation UpdateVoiceFileUploadRequest(
    $input: UpdateVoiceFileUploadRequestInput!
    $condition: ModelVoiceFileUploadRequestConditionInput
  ) {
    updateVoiceFileUploadRequest(input: $input, condition: $condition) {
      id
      sessionId
      filename
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVoiceFileUploadRequest = /* GraphQL */ `
  mutation DeleteVoiceFileUploadRequest(
    $input: DeleteVoiceFileUploadRequestInput!
    $condition: ModelVoiceFileUploadRequestConditionInput
  ) {
    deleteVoiceFileUploadRequest(input: $input, condition: $condition) {
      id
      sessionId
      filename
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomPromptMenu = /* GraphQL */ `
  mutation CreateCustomPromptMenu(
    $input: CreateCustomPromptMenuInput!
    $condition: ModelCustomPromptMenuConditionInput
  ) {
    createCustomPromptMenu(input: $input, condition: $condition) {
      id
      tenantId
      sortOrder
      name
      value
      label
      promptId
      useSystemAudio
      useVoiceRecognition
      inputFormPlaceholder
      outputFormPlaceholder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomPromptMenu = /* GraphQL */ `
  mutation UpdateCustomPromptMenu(
    $input: UpdateCustomPromptMenuInput!
    $condition: ModelCustomPromptMenuConditionInput
  ) {
    updateCustomPromptMenu(input: $input, condition: $condition) {
      id
      tenantId
      sortOrder
      name
      value
      label
      promptId
      useSystemAudio
      useVoiceRecognition
      inputFormPlaceholder
      outputFormPlaceholder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomPromptMenu = /* GraphQL */ `
  mutation DeleteCustomPromptMenu(
    $input: DeleteCustomPromptMenuInput!
    $condition: ModelCustomPromptMenuConditionInput
  ) {
    deleteCustomPromptMenu(input: $input, condition: $condition) {
      id
      tenantId
      sortOrder
      name
      value
      label
      promptId
      useSystemAudio
      useVoiceRecognition
      inputFormPlaceholder
      outputFormPlaceholder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomPrompt = /* GraphQL */ `
  mutation CreateCustomPrompt(
    $input: CreateCustomPromptInput!
    $condition: ModelCustomPromptConditionInput
  ) {
    createCustomPrompt(input: $input, condition: $condition) {
      id
      summaryPromptSystemRole
      summaryPromptUserRole
      mergePromptSystemRole
      mergePromptUserRole
      subPromptSystemRole
      subPromptUserRole
      subPromptSystemRoleEng
      subPromptUserRoleEng
      summaryPromptSystemRoleEng
      summaryPromptUserRoleEng
      mergePromptSystemRoleEng
      mergePromptUserRoleEng
      mergePattern
      chunkSize
      chunkOverlap
      inputMaxLength
      useBedrockModel
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomPrompt = /* GraphQL */ `
  mutation UpdateCustomPrompt(
    $input: UpdateCustomPromptInput!
    $condition: ModelCustomPromptConditionInput
  ) {
    updateCustomPrompt(input: $input, condition: $condition) {
      id
      summaryPromptSystemRole
      summaryPromptUserRole
      mergePromptSystemRole
      mergePromptUserRole
      subPromptSystemRole
      subPromptUserRole
      subPromptSystemRoleEng
      subPromptUserRoleEng
      summaryPromptSystemRoleEng
      summaryPromptUserRoleEng
      mergePromptSystemRoleEng
      mergePromptUserRoleEng
      mergePattern
      chunkSize
      chunkOverlap
      inputMaxLength
      useBedrockModel
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomPrompt = /* GraphQL */ `
  mutation DeleteCustomPrompt(
    $input: DeleteCustomPromptInput!
    $condition: ModelCustomPromptConditionInput
  ) {
    deleteCustomPrompt(input: $input, condition: $condition) {
      id
      summaryPromptSystemRole
      summaryPromptUserRole
      mergePromptSystemRole
      mergePromptUserRole
      subPromptSystemRole
      subPromptUserRole
      subPromptSystemRoleEng
      subPromptUserRoleEng
      summaryPromptSystemRoleEng
      summaryPromptUserRoleEng
      mergePromptSystemRoleEng
      mergePromptUserRoleEng
      mergePattern
      chunkSize
      chunkOverlap
      inputMaxLength
      useBedrockModel
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const entryRequest = /* GraphQL */ `
  mutation EntryRequest(
    $request: String
    $mode: String
    $promptId: String
    $inputLang: String
    $outputLang: String
  ) {
    entryRequest(
      request: $request
      mode: $mode
      promptId: $promptId
      inputLang: $inputLang
      outputLang: $outputLang
    )
  }
`;
export const sendRequest = /* GraphQL */ `
  mutation SendRequest($request: SendRequestInput!) {
    sendRequest(request: $request) {
      id
      message
      response
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMonthlyProcessedTextCount = /* GraphQL */ `
  mutation UpdateMonthlyProcessedTextCount($incrementValue: Int!) {
    updateMonthlyProcessedTextCount(incrementValue: $incrementValue)
  }
`;
export const entryVoiceFile = /* GraphQL */ `
  mutation EntryVoiceFile($sessionId: String, $filename: String) {
    entryVoiceFile(sessionId: $sessionId, filename: $filename)
  }
`;
