export const jstNow = () => {
  // YYYY-MM-DDTHH:mm:ss.fffZ
  return new Date(
    Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000,
  );
};

export const formatDateTimeYYYYMMDD_HHmmss = (dateTime) => {
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const date = String(dateTime.getDate()).padStart(2, "0");
  const hour = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  const seconds = String(dateTime.getSeconds()).padStart(2, "0");

  return `${year}${month}${date}_${hour}${minutes}${seconds}`;
};
