class ProperNounsSettingRepository {
  static loadSettings() {
    const properNounsSettings = localStorage.getItem("properNounsSettings");

    if (properNounsSettings) {
      return JSON.parse(properNounsSettings);
    }
    return {};
  }

  static updateSettings(key, value) {
    const properNounsSettings = this.loadSettings();
    properNounsSettings[key] ??= {};
    properNounsSettings[key].isValid = value;
    localStorage.setItem(
      "properNounsSettings",
      JSON.stringify(properNounsSettings),
    );
  }
}

export default ProperNounsSettingRepository;
