import PropTypes from "prop-types";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { summaryModeOptions } from "../constants/summaryModeOptions";
import { useTheme } from "@mui/material/styles";
import CustomPromptMenuRepository from "repositories/CustomPromptMenuRepository";
import { useEffect, useState } from "react";
import { isIphone } from "../helper/deviceHelper";

const customPromptMenuRepository = new CustomPromptMenuRepository();

const labels = {
  title: "文章をきれいにする設定",
};

const SummaryModeSelect = ({ option, onChange, disabled }) => {
  const theme = useTheme();
  let _summaryModeOptions = { ...summaryModeOptions };
  if (isIphone()) {
    delete _summaryModeOptions.meetingWithSystemAudio;
  }
  const [summaryModeProperty, setSummaryModeProperty] =
    useState(_summaryModeOptions);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const getCustomPromptMenu = async () => {
    const response = await customPromptMenuRepository.findAll();

    let updatedMenus = {};
    if (response && response.length > 0) {
      // カスタムプロンプトの取得
      // key: customPrompt_{index} value: customPrompt_{index} label: CustomPromptMenuTable.name promptId: CustomPromptMenuTable.promptId
      updatedMenus = response.reduce((acc, data) => {
        acc[data.value] = {
          value: data.value,
          label: data.label,
          promptId: data.promptId,
          sortOrder: data.sortOrder,
          useSystemAudio: data.useSystemAudio,
          inputFormPlaceholder: data.inputFormPlaceholder,
          outputFormPlaceholder: data.outputFormPlaceholder,
        };
        return acc;
      }, {});

      setSummaryModeProperty({
        ..._summaryModeOptions,
        ...updatedMenus,
      });
    }
  };

  useEffect(() => {
    (async () => {
      await getCustomPromptMenu();
    })();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        variant="subtitle1"
        sx={{
          margin: "auto 0",
          mr: "1rem",
          mb: theme.spacing(1),
        }}
      >
        {labels.title}
      </Typography>
      <FormControl sx={{ width: "100%" }}>
        <Select
          id="SummaryModeSelect"
          value={option}
          onChange={handleChange}
          disabled={disabled}
          sx={{ height: theme.spacing(9) }}
        >
          {Object.entries(summaryModeProperty)
            .sort(
              ([, optionA], [, optionB]) =>
                optionA.sortOrder - optionB.sortOrder,
            )
            .map(([key, _option]) => (
              <MenuItem id={`option-${key}`} key={key} value={_option}>
                <Typography variant="subtitle2">{_option.label}</Typography>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

SummaryModeSelect.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SummaryModeSelect;
