import { createTheme } from "@mui/material";

export const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: "#67C6DD",
      contrastText: "#fff",
      light: "#A0C0CA",
    },
    secondary: {
      main: "#9DC833",
      contrastText: "#fff",
    },
    monoqlo: {
      main: "#3C4A60",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
    subtitle1: {
      fontSize: 12,
      lineHeight: "16px",
      fontColor: "#1C222C",
      fontWeight: 600,
      leadingTrim: "both",
      textEdge: "cap",
    },
    subtitle2: {
      fontSize: 12,
      lineHeight: "16px",
      fontColor: "#5D5F5F",
      fontWeight: 300,
      leadingTrim: "both",
      textEdge: "cap",
    },
    body1: {
      fontSize: 14,
      fontColor: "#1C222C",
      fontWeight: 300,
    },
    body2: {
      fontSize: 14,
      fontColor: "#B7B7B7",
      fontWeight: 300,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        fontWeight: 600,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontWeight: 600,
          ...(ownerState.size === "large" && {
            height: "52px",
            fontSize: "18px",
          }),
          ...(ownerState.size === "medium" && {
            height: "44px",
            fontSize: "15px",
          }),
          ...(ownerState.size === "small" && {
            height: "44px",
            fontSize: "14px",
            lineHeight: "14px",
          }),
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        inputProps: {
          sx: {
            height: "36px",
            pt: "0px",
            pb: "0px",
          },
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          mt: "0px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          backgroundColor: "#fff",
          color: "#1C222C",
          borderBottom: "1px solid lightgray",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          p: 0,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          lineHeight: "16px",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          height: "16px",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: "#3c4a68",
        },
      },
    },
  },
});
