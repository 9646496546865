import PropTypes from "prop-types";
import { languageModeOptions } from "../constants/languageModeOptions";
import { FormControl, Typography, Select, MenuItem } from "@mui/material";
import { useTheme } from "@emotion/react";

const LanguageModeSelect = ({ option, onChange, disabled, flowType }) => {
  const theme = useTheme();
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <FormControl sx={{ width: "50%" }}>
      <Select
        id={`${flowType}LangModeSelect`}
        value={option}
        onChange={handleChange}
        disabled={disabled}
        sx={{ height: theme.spacing(9) }}
      >
        {Object.entries(languageModeOptions).map(([key, _option]) => (
          <MenuItem id={`option-${key}`} key={key} value={_option}>
            <Typography variant="subtitle2">{_option.label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

LanguageModeSelect.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  flowType: PropTypes.string,
};

export default LanguageModeSelect;
