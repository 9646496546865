import { FormGroup, FormControlLabel } from "@mui/material";
import { IOSSwitch } from "./IOSSwitch";

export default function enabledAutoSummarizationSettingSwitch(props) {
  const { id, onChange, checked, label } = props;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <IOSSwitch
            id={id}
            onChange={onChange}
            checked={checked}
            sx={{ mr: "0.5rem" }}
          />
        }
        label={label}
        sx={{ margin: 0 }}
      />
    </FormGroup>
  );
}
