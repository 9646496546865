export const summaryModeOptions = {
  normal: {
    value: "normal",
    label: "ポイント整理",
    sortOrder: 1,
    promptId: "97a6706d-0820-48c8-90a5-54b1ee676c2c",
    useSystemAudio: false,
    inputFormPlaceholder: `このモードでは、入力した文章のポイントを箇条書きし、読みやすく校正します。\n\n◆用途\n・日報などのレポート\n・自分用のメモ\n・メールチェーンの要約\n・記事やドキュメントの要約\n・英文のサマリ作成\n\n◆話者自動認識オプション\n音声から発言者を認識し、発言内容と発言者を自動的に紐づけながら書き起こしを行います（オフライン会議でも利用可能です）`,
    outputFormPlaceholder: `ポイント\n・入力した文章のポイントが箇条書きされます。\n・入力した文章のポイントが箇条書きされます。\n・入力した文章のポイントが箇条書きされます。\n\n校正文\n入力した文章を読みやすいように校正して出力します。音声入力をした場合に、口語を読みやすく校正することができます。`,
  },
  detailedMeeting: {
    value: "detailedMeeting",
    label: "詳細会議メモ",
    sortOrder: 2,
    promptId: "89766de0-bd68-442f-972f-217119e0ebcf",
    useSystemAudio: false,
    inputFormPlaceholder: `発言者と決定プロセスの詳細な記録が可能になり、会議メモを確認する事で議論が把握できます。\n\n◆使い方\n・「音声を入力」ボタンをクリックして、音声入力を開始します\n・Teams、Zoomなどのツールで作成した文字起こしを入力します\n\n◆用途\n・会議のサマリメモの作成（オフライン/オンライン\n\n◆話者自動認識オプション\n音声から発言者を認識し、発言内容と発言者を自動的に紐づけながら書き起こしを行います（オフライン会議でも利用可能です）`,
    outputFormPlaceholder: `### 参加者\n-発言者0\n-発言者1\n-発言者2\n\n### 議題一覧\n1. 会議モード詳細メモについて\n\n### 議題1:会議モード詳細メモについて\n**発言者0:**発言者ごとのポイントとなる発言が出力されます。\n\n**発言者1:**発言者がわかるので、議論の流れが把握しやすくなります。\n\n**発言者0:**なるほど、とても便利な機能ですね。\n\n### 次のアクション\n-発言者0:会議の中で話されたアクションが出力されます。\n-発言者1:会議の中で話されたアクションが出力されます。\n\n以上`,
  },
  meetingWithSystemAudio: {
    value: "meetingWithSystemAudio",
    label: "詳細ウェブ会議音声入力",
    sortOrder: 3,
    promptId: "89766de0-bd68-442f-972f-217119e0ebcf",
    useSystemAudio: true,
    inputFormPlaceholder: `このモードは、ウェブ会議の音声を取得して、議題、アクションをまとめた会議メモを作成します。会議の開始時にこのモードを選択してください。\n\n◆使い方\n・「音声を入力」ボタンをクリックして、音声入力を開始します。設定は画面の指示に従ってください。\n\n◆用途\n・オンライン会議のサマリメモの作成\n・オンラインセミナーの文字起こし・メモの作成\n・Youtube等の動画の文字起こし・メモの作成\n\n◆話者自動認識オプション\n音声から発言者を認識し、発言内容と発言者を自動的に紐づけながら書き起こしを行います（オフライン会議でも利用可能です）\n\n◆注意事項\n・ユーザーのマイクの音声は常に入力されます。PC側のマイクのOn/Offで自身の音声の入力を切り替えてご利用ください。`,
    outputFormPlaceholder: `### 参加者\n-発言者0\n-発言者1\n-発言者2\n\n### 議題一覧\n1. 会議モード詳細メモについて\n\n### 議題1:会議モード詳細メモについて\n**発言者0:**発言者ごとのポイントとなる発言が出力されます。\n\n**発言者1:**発言者がわかるので、議論の流れが把握しやすくなります。\n\n**発言者0:**なるほど、とても便利な機能ですね。\n\n### 次のアクション\n-発言者0:会議の中で話されたアクションが出力されます。\n-発言者1:会議の中で話されたアクションが出力されます。\n\n以上`,
  },
  meeting: {
    value: "meeting",
    label: "簡易会議メモ",
    sortOrder: 4,
    promptId: "6a1d6c1c-74a2-413d-8a38-f11fc8f9d640",
    useSystemAudio: false,
    inputFormPlaceholder: `このモードは、概要、議題、アクションをまとめた会議メモを作成します。会議の文字起こしを入力してください\n\n◆使い方\n・「音声を入力」ボタンをクリックして、音声入力を開始します\n・Teams、Zoomなどのツールで作成した文字起こしを入力します\n\n◆用途\n・会議のサマリメモの作成 (オフライン/オンライン）\n\n◆話者自動認識オプション\n音声から発言者を認識し、発言内容と発言者を自動的に紐づけながら書き起こしを行います（オフライン会議でも利用可能です）\n\n【出力例】\n発言者0:\n今日の会議の目的は、新しいプロジェクトの進捗状況を確認することです。\n発言者1:\n先週の進捗について報告します。まず、新しい機能の開発についてですが、・・・`,
    outputFormPlaceholder: `参加者\n・発言者0\n・発言者1\n・発言者2\n\n概要\n会議の全体の概要を把握しやすいように短い文章でまとめます。\n\n議題\n・会議の議題を自動判別し、それぞれの議題についてまとめます。\n・会議の議題を自動判別し、それぞれの議題についてまとめます。\n\n各議題のポイント\n・議題毎のポイントを箇条書きでまとめます。\n・議題毎のポイントを箇条書きでまとめます。\n・議題毎のポイントを箇条書きでまとめます。\n\n次のアクション\n・会議の中で話されたアクションをまとめます。\n・会議の中で話されたアクションをまとめます。`,
  },
};

export const customPromptModePrefix = "customPrompt_";
