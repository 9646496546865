import { Button, List, ListItem, Typography, Grid } from "@mui/material";
import { ButtonWithAnalytics } from "components/ComponentWithAnalytics";
import DisplayWords from "../../components/DisplayWords";
import PropTypes from "prop-types";
import ProperNounListHeader from "./ProperNounListHeader";
import InfomationMessage from "../../components/InfomationMessage";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ProperNounsSettingRepository from "../../repositories/ProperNounsSettingRepository";
import { properNounCategories } from "constants/properNounCategories";

import { downloadCSV } from "../../helper/fileHelper";

const sampleCSVData =
  "表記,読み\nおまとめ忍者,おまとめにんじゃ\nおまとめ忍者辞書テスト,おまとめにんじゃじしょ";
const personalProperNounCategoryName = properNounCategories.personal.id;

const buttonSize = {
  height: "2.8rem",
};

const RegisteredWordList = ({
  profiledWordsFileds,
  registerProfiledWords,
  registerProfiledWordsErrors,
  onChangeWord,
  removeWord,
  handleSubmit,
  onInputCSV,
  personalRegisteredWords,
  showBulkRegistration = true,
  moveToEdit,
}) => {
  //inputのバリューを管理する
  const [inputCSV, setInputCSV] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleInputCSV = async (event) => {
    if (event.target.files[0]) {
      onInputCSV(event.target.files[0]);
      setInputCSV("");
    }
  };

  const handleChangeSwitch = (event) => {
    ProperNounsSettingRepository.updateSettings(
      personalProperNounCategoryName,
      event.target.checked,
    );
    setIsChecked(event.target.checked);
  };

  const handleClickSample = async () => {
    downloadCSV("sample.csv", sampleCSVData);
  };

  useEffect(() => {
    (async () => {
      const settings = await ProperNounsSettingRepository.loadSettings();
      if (settings[personalProperNounCategoryName]) {
        setIsChecked(settings[personalProperNounCategoryName].isValid);
      }
    })();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <ProperNounListHeader
        title="プライベート辞書"
        message="登録したい単語の追加が可能です。パブリック辞書と同じ表記の登録は，プライベート辞書が優先されます。"
        showSwitch
        onChangeSwitch={handleChangeSwitch}
        isChecked={isChecked}
      />
      {showBulkRegistration && (
        <Grid item>
          <Grid item></Grid>
          <Grid item>
            <Typography sx={{ textAlign: "left" }} variant="body1">
              直接入力、またはファイルから一括で辞書を入力します。ファイルを作成するにはサンプルファイルをダウンロード・編集してください。
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              mb: "1rem",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="text"
              component="label"
              onClick={handleClickSample}
              sx={buttonSize}
            >
              サンプルファイル
            </Button>
            <Button
              variant="outlined"
              component="label"
              sx={{ ml: "0.4rem", ...buttonSize }}
            >
              ファイルから読込
              <input
                type="file"
                hidden
                accept=".csv"
                onChange={handleInputCSV}
                value={inputCSV}
              />
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid item>
        <List sx={{ padding: "0" }}>
          {personalRegisteredWords.length === 0 && (
            <ListItem>
              <InfomationMessage message="プライベート辞書に登録されている単語はありません。" />
            </ListItem>
          )}
          <DisplayWords
            profiledWordsFileds={profiledWordsFileds}
            registerProfiledWords={registerProfiledWords}
            onChangeWord={onChangeWord}
            onClickClear={removeWord}
            registerProfiledWordsErrors={registerProfiledWordsErrors}
            personalRegisteredWords={personalRegisteredWords}
            moveToEdit={moveToEdit}
          />
          <ListItem
            sx={{
              display: { xs: "none", md: "flex" },
              padding: "0.6rem 0.75rem",
              justifyContent: "center",
            }}
          >
            <ButtonWithAnalytics
              eventName="clickRegisterNewWord"
              id="addNewWordPc"
              type="submit"
              variant="text"
              color="primary"
              component={Link}
              to="/register-new-word"
              size="small"
              sx={{ height: "1.25rem", gap: "0.5rem" }}
            >
              <AddCircleOutlineIcon sx={{ fontSize: "14px" }} />
              <Typography variant="body1">登録単語の追加</Typography>
            </ButtonWithAnalytics>
          </ListItem>
        </List>
      </Grid>
    </form>
  );
};

export default RegisteredWordList;

RegisteredWordList.propTypes = {
  profiledWordsFileds: PropTypes.array,
  registerProfiledWords: PropTypes.func,
  registerProfiledWordsErrors: PropTypes.object,
  onChangeWord: PropTypes.func,
  addWord: PropTypes.func,
  removeWord: PropTypes.func,
  handleSubmit: PropTypes.func,
  onInputCSV: PropTypes.func,
  personalRegisteredWords: PropTypes.array,
  showBulkRegistration: PropTypes.bool,
  moveToEdit: PropTypes.func,
};
