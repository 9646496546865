class WordRepository {
  static loadWords() {
    const storedWords = localStorage.getItem("registeredWords");
    if (storedWords) {
      return JSON.parse(storedWords);
    }
    return [];
  }

  static saveWords(wordList) {
    const storedWords = this.loadWords();
    storedWords.push(wordList[0]);
    localStorage.setItem("registeredWords", JSON.stringify(storedWords));
  }

  static updateWord(index, wordSet) {
    const storedWords = this.loadWords();
    storedWords[index].word = wordSet.word;
    storedWords[index].reading = wordSet.reading;
    localStorage.setItem("registeredWords", JSON.stringify(storedWords));
  }

  static removeWord(index) {
    const storedWords = this.loadWords();
    storedWords.splice(index, 1);
    localStorage.setItem("registeredWords", JSON.stringify(storedWords));
  }
}

export default WordRepository;
