import { DragAudioFileZone } from "components/DragAudioFileZone";
import { Box, Typography } from "@mui/material";
import { ButtonWithAnalytics } from "components/ComponentWithAnalytics";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { getVoiceRecognitionAPIOneTimeAppKey } from "helper/voiceRecognitionHelper";
import { voiceFileUpload } from "repositories/VoiceFileUploadRepository";
import { useState, useContext } from "react";
import { useSnackbar } from "notistack";
import { TenantOptionsContext } from "components/TenantOptionsProvider";
import { Navigate } from "react-router-dom";
import AnalyticsWithTenant from "helper/AnalyticsWithTenant";
import useWordRegister from "hooks/useWordRegister";
import createProfileWordsParameter from "helper/amiVoiceHelper/createProfileWordsParameter";

export default function FileUpload() {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isDragFile, setIsDragFile] = useState(false);
  const allowExtentions = ".(mp3|wav)$";

  //プライベート辞書と共通辞書から登録単語を呼び出す
  const { loadProfiledWords, convertListToString, loadCorporateProperNouns } =
    useWordRegister();

  const { tenantOptions } = useContext(TenantOptionsContext);
  if (
    tenantOptions == null ||
    (tenantOptions && !tenantOptions.file_upload_enabled)
  ) {
    // テナントオプションがないorファイルアップロード機能が無効の場合は報告入力画面にリダイレクト
    return <Navigate to="/" replace />;
  } else if (!tenantOptions) {
    return null; // tenantOptionsがロードされるまで一旦nullを返す
  }

  const validateFile = (file) => {
    if (!file) {
      enqueueSnackbar("ファイルをドロップまたは、選択してください。", {
        variant: "error",
      });
      setIsUploading(false);
      return false;
    }
    if (!file.name.match(allowExtentions)) {
      enqueueSnackbar("ファイル形式は.mp3または、.wavのみ対応しています。", {
        variant: "error",
      });
      return false;
    }
    if (file.size > 2147483648) {
      enqueueSnackbar("ファイルサイズは2GB以下にしてください。", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const handleAnalysis = async () => {
    setIsUploading(true);
    setIsDragFile(false);

    if (!validateFile(file)) {
      setIsUploading(false);
      return;
    }
    try {
      const uploadSession = await getVoiceFileUploadResponse(file);
      await voiceFileUpload(uploadSession.sessionid, file.name);
      AnalyticsWithTenant.record({
        name: "fileUpload",
      });
      enqueueSnackbar("ファイルのアップロードに成功しました。", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("ファイルのアップロードに失敗しました。", {
        variant: "error",
      });
    } finally {
      setIsUploading(false);
    }
  };

  const getVoiceFileUploadResponse = async (file) => {
    const profileWords = await createProfileWordsParameter(
      loadProfiledWords,
      convertListToString,
      loadCorporateProperNouns,
    );
    const apiKey = await getVoiceRecognitionAPIOneTimeAppKey(true);

    const dataParams = {
      grammarFileNames: "-a-general",
      loggingOptOut: "True",
      profileWords: profileWords,
    };
    //AmiVoiceAPIに送るデータをエンコード
    const encodeedDataParams = Object.entries(dataParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join(" ");

    const formData = new FormData();
    formData.append("d", encodeedDataParams);
    formData.append("u", apiKey);
    formData.append("a", file);

    try {
      const response = await fetch(
        "https://acp-api-async.amivoice.com/v1/recognitions",
        { method: "POST", body: formData },
      );
      if (!response.ok) {
        throw new Error(`Response Error: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      throw new Error(
        `ファイルアップロードを使用したAmiVoiceへのリクエストに失敗しました:${error}`,
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        pt: "5rem",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <DragAudioFileZone
        onChange={(files) => {
          setFile(files[0]);
        }}
        isUploading={isUploading}
        setIsDragFile={setIsDragFile}
      />
      <ButtonWithAnalytics
        eventName="clickStartFileUpload"
        startIcon={<AutoAwesomeOutlinedIcon />}
        fullWidth
        variant="contained"
        color="primary"
        disabled={!isDragFile}
        sx={{
          margin: "1rem 0",
          width: { xs: "100%", md: "160px" },
        }}
        onClickFunc={handleAnalysis}
      >
        解析
      </ButtonWithAnalytics>
      <Typography variant="subtitle2" align="left">
        解析が完了すると、メールでお知らせします。
      </Typography>
    </Box>
  );
}
