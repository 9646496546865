import { useState, useEffect, useRef } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import MonthlyTenantProcessedTextCountRepository from "../repositories/MonthlyTenantProcessedTextCountRepository";
import { useSnackbar } from "notistack";
import { getTenantUserGroup } from "helper/userInfoHelper";

export const limitStatus = Object.freeze({
  exceeded: "exceeded",
  closeToLimit: "closeToLimit",
  safe: "safe",
});

const closeToLimitRate = 0.8;

const monthlyTenantProcessedTextCountRepository =
  new MonthlyTenantProcessedTextCountRepository();

const useUsageSituation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [monthlyTextCount, setMonthlyTextCount] = useState(null);
  const currentMonthTextCountRef = useRef(null);
  const [maxMonthlyTextCount, setMaxMonthlyTextCount] = useState(null);
  const [hasValidUsage, setHasValidUsage] = useState(false);
  const [usageRate, setUsageRate] = useState(0);
  const { user } = useAuthenticator((context) => [context.user]);
  const userInfo = user.getSignInUserSession().getIdToken().payload;
  const tenantId = getTenantUserGroup(userInfo);
  const { enqueueSnackbar } = useSnackbar();
  const [usageLimitStatus, setUsageLimitStatus] = useState(null);

  const isValidTextCount = (value) => {
    return Number.isInteger(value);
  };

  const refreshActiveUsage = async () => {
    setIsLoading(true);
    await loadActiveUsageSituationByTenantId(tenantId);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      refreshActiveUsage();
    })();
  }, []);

  useEffect(() => {
    if (!hasValidUsage) {
      setUsageRate(0);
      return;
    }

    if (maxMonthlyTextCount === 0) {
      setUsageRate(100);
      return;
    }

    if (monthlyTextCount > maxMonthlyTextCount) {
      setUsageRate(100);
      return;
    }

    setUsageRate((monthlyTextCount / maxMonthlyTextCount) * 100);
  }, [monthlyTextCount, maxMonthlyTextCount, hasValidUsage]);

  const loadActiveUsageSituationByTenantId = async (tenantId) => {
    try {
      const monthlyTenantProcessedTextCount =
        await monthlyTenantProcessedTextCountRepository.find(tenantId, true);

      if (!monthlyTenantProcessedTextCount?.planId?.isProcessedTextBasedPlan) {
        setHasValidUsage(false);
        return;
      }

      if (
        !isValidTextCount(monthlyTenantProcessedTextCount?.monthlyTextCount) ||
        !isValidTextCount(
          monthlyTenantProcessedTextCount?.planId?.maxSummarizeTextCount,
        )
      ) {
        setHasValidUsage(false);
        throw Error("Invalid usage text count.", {
          cause: { code: "invalidUsageTextCount" },
        });
      }

      setHasValidUsage(true);
      setMonthlyTextCount(monthlyTenantProcessedTextCount?.monthlyTextCount);
      currentMonthTextCountRef.current =
        monthlyTenantProcessedTextCount?.monthlyTextCount;
      setMaxMonthlyTextCount(
        monthlyTenantProcessedTextCount?.planId?.maxSummarizeTextCount,
      );

      const isExceeded =
        monthlyTenantProcessedTextCount?.monthlyTextCount >=
        monthlyTenantProcessedTextCount?.planId?.maxSummarizeTextCount;
      const isCloseToLimit =
        monthlyTenantProcessedTextCount?.monthlyTextCount >=
        monthlyTenantProcessedTextCount?.planId?.maxSummarizeTextCount *
          closeToLimitRate;
      let status;
      if (isExceeded) {
        status = limitStatus.exceeded;
      } else if (isCloseToLimit) {
        status = limitStatus.closeToLimit;
      } else {
        status = limitStatus.safe;
      }
      setUsageLimitStatus(status);
    } catch (e) {
      console.error(e);
      let message = "エラーが発生しました。";
      // TODO: エラー処理の共通化とメッセージのマッピング
      if ("invalidUsageTextCount" === e?.cause?.code) {
        message =
          "有効な利用文字数が取得できませんでした。もう一度お試しください。それでも解決しない場合は、管理者にお問い合わせください。";
      }
      if ("moreThan2ActiveMonthlyTenantProcessedTextCount" === e?.cause?.code) {
        message =
          "有効な利用状況が複数取得されました。もう一度お試しください。それでも解決しない場合は、管理者にお問い合わせください。";
      }
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  return {
    monthlyTextCount,
    maxMonthlyTextCount,
    currentMonthTextCountRef,
    hasValidUsage,
    usageRate,
    isLoading,
    refreshActiveUsage,
    usageLimitStatus,
  };
};

export default useUsageSituation;
