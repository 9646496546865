import { useRef } from "react";
import useUsageSituation from "../hooks/useUsageSituation";
import { useSnackbar } from "notistack";
import { TenantPlanContext } from "components/TenantPlanProvider";
import { useContext } from "react";

const useUsageLimitExceeded = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { tenantPlan } = useContext(TenantPlanContext);
  const {
    currentMonthTextCountRef,
    maxMonthlyTextCount,
    hasValidUsage,
    isLoading,
    refreshActiveUsage,
  } = useUsageSituation();
  const currentIsUsageLimitExceeded = useRef(false);
  const TIMEOUT_DURATION = 5000;
  const POLLING_INTERVAL = 1000;

  const checkUsageLimitExceeded = async () => {
    if (!tenantPlan?.isProcessedTextBasedPlan) {
      currentIsUsageLimitExceeded.current = false;
      return;
    }

    await refreshActiveUsage();

    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        reject(
          new Error("利用状況が取得できませんでした。機能をご利用できません。"),
        );
      }, TIMEOUT_DURATION);
    });

    const dataPromise = new Promise((resolve) => {
      const checkData = () => {
        if (!isLoading) {
          resolve();
        } else {
          // 1秒後に再チェック
          setTimeout(checkData, POLLING_INTERVAL);
        }
      };
      checkData();
    });

    try {
      // timeoutとdataのどちらかが先に完了するまで待つ
      await Promise.race([timeoutPromise, dataPromise]);

      if (
        !hasValidUsage ||
        currentMonthTextCountRef.current >= maxMonthlyTextCount
      ) {
        let errorMessage = !hasValidUsage
          ? "利用状況が取得できませんでした。機能をご利用できません。"
          : "利用可能文字数を超えました。機能をご利用できません。";

        enqueueSnackbar(errorMessage, { variant: "error" });
        currentIsUsageLimitExceeded.current = true;
        return;
      }

      currentIsUsageLimitExceeded.current = false;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return {
    currentIsUsageLimitExceeded,
    checkUsageLimitExceeded,
  };
};

export default useUsageLimitExceeded;
