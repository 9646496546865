import { Analytics, Auth } from "aws-amplify";

class AnalyticsWithTenantClass {
  constructor() {
    this.analytics = Analytics;
    this.auth = Auth;
  }

  async record(event) {
    try {
      const tenantId = await this.fetchTenantId();
      event.attributes ??= {};
      event.attributes.tenant = tenantId;
      await this.analytics.record(event);
    } catch (error) {
      console.error("Error recording event:", error);
    }
  }

  async fetchTenantId() {
    const currentSession = await this.auth.currentSession();
    const tenantId = currentSession.getIdToken().payload["custom:tenant_id"];
    return tenantId;
  }
}

const AnalyticsWithTenant = new AnalyticsWithTenantClass();
export default AnalyticsWithTenant;
