import ToggleButton from "@mui/material/ToggleButton";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function CustomToggleButton(props) {
  const {
    toggleValue,
    isSelected,
    handleOnChange,
    isDisabled,
    pcLabel,
    spLabel,
  } = props;
  const theme = useTheme();
  const displayLabel = useMediaQuery(theme.breakpoints.up("sm"))
    ? pcLabel
    : spLabel;

  return (
    <ToggleButton
      value={toggleValue}
      id={toggleValue}
      color="primary"
      selected={isSelected}
      onChange={handleOnChange}
      disabled={isDisabled}
      sx={{
        whiteSpace: "nowrap",
        height: theme.spacing(9),
        minWidth: { xs: "7rem", sm: "9rem" },
        borderRadius: "1rem",
        border: "1px solid",
        "&.Mui-selected": {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
          color: "white",
          "&:hover": {
            borderColor: theme.palette.primary.dark,
            backgroundColor: theme.palette.primary.dark,
          },
          "&.Mui-disabled": {
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.light,
          },
        },
      }}
    >
      {displayLabel}: {isSelected ? "ON" : "OFF"}
    </ToggleButton>
  );
}

CustomToggleButton.propTypes = {
  toggleValue: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  pcLabel: PropTypes.string.isRequired,
  spLabel: PropTypes.string.isRequired,
};
