import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { ButtonWithAnalytics } from "./ComponentWithAnalytics";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

/**
 * 与えられたテキストから発言者ラベルを抽出する
 * @param {string} text
 * @returns {string[]} 発言者ラベルのリスト
 */
const extractSpeakers = (text) => {
  const speakerRegex = /^(.*):\s*$/gm;
  const speakers = new Set();
  let match;

  while ((match = speakerRegex.exec(text)) !== null) {
    speakers.add(match[1]);
  }

  return Array.from(speakers).sort();
};

export default function ReplacementLabelForm(props) {
  const {
    messageInput,
    handleMessageInputChange,
    disabled,
    setOpen,
    setLabel,
  } = props;
  const labelList = extractSpeakers(messageInput);

  const [replacements, setReplacements] = useState(labelList.map(() => ""));

  useEffect(() => {
    // メッセージが空になった場合(クリアの実行，手動削除)は置換後のラベルをリセットする
    if (!messageInput) {
      setReplacements([""]);
    }
  }, [messageInput]);

  const handleReplacementChange = (index, value) => {
    const newReplacements = [...replacements];
    newReplacements[index] = value;
    setReplacements(newReplacements);
  };

  const handleExecute = () => {
    const replacedText = labelList.reduce((currentText, label, index) => {
      if (replacements[index]) {
        const regex = new RegExp(`${label}:`, "g");
        return currentText.replace(regex, `${replacements[index]}:`);
      }
      return currentText;
    }, messageInput);

    handleMessageInputChange(replacedText);
    setReplacements(labelList.map(() => ""));
  };

  const handleOpenTranscriptsPerLabel = (label) => {
    setLabel(label);
    setOpen(true);
  };

  return (
    <Stack spacing={2}>
      {labelList.length === 0 ? (
        <Typography variant="body2" sx={{ color: "gray" }}>
          発言者ラベルが見つかりません
        </Typography>
      ) : (
        <>
          {labelList.map((label, index) => (
            <Stack
              direction="row"
              key={`${label}-${index}`}
              spacing={2}
              alignItems="center"
            >
              <Typography
                id={`labelBeforeReplacement-${index}`}
                sx={{
                  width: "8rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {label}
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                sx={{ width: "5rem", height: "36px", px: 0 }}
                onClick={() => {
                  handleOpenTranscriptsPerLabel(label);
                }}
              >
                発言例
              </Button>
              <TextField
                id={`labelAfterReplacement-${index}`}
                variant="outlined"
                placeholder="置換後の発言者ラベル"
                fullWidth
                value={replacements[index]}
                onChange={(e) => handleReplacementChange(index, e.target.value)}
              />
            </Stack>
          ))}
          <Stack sx={{ alignItems: "flex-end" }}>
            <ButtonWithAnalytics
              id="executeReplacementButton"
              variant="contained"
              size="small"
              sx={{ width: "3rem", height: "2.5rem" }}
              eventName="executeReplacement"
              onClickFunc={handleExecute}
              disabled={
                replacements.length === 0 ||
                replacements.every((item) => item === "") ||
                disabled
              }
            >
              実行
            </ButtonWithAnalytics>
          </Stack>
        </>
      )}
    </Stack>
  );
}

ReplacementLabelForm.propTypes = {
  messageInput: PropTypes.string.isRequired,
  handleMessageInputChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  setOpen: PropTypes.func,
  setLabel: PropTypes.func,
};
