import * as React from "react";
import { Stack } from "@mui/material";
import { ButtonWithAnalytics } from "./ComponentWithAnalytics";
import { Link as RouterLink } from "react-router-dom";
import { useContext } from "react";
import { TenantOptionsContext } from "./TenantOptionsProvider";
import { TenantPlanContext } from "./TenantPlanProvider";

const headerItemStyle = {
  fontSize: "12px",
  color: "#1c222c",
  fontWeight: "300",
  mr: "0.875rem",
  paddingX: 0,
  width: "auto",
};

export const MainListHeaderItems = () => {
  const { tenantOptions } = useContext(TenantOptionsContext);
  const { tenantPlan } = useContext(TenantPlanContext);

  return (
    <React.Fragment>
      <Stack direction="row">
        <ButtonWithAnalytics
          eventName="viewHomeInputReport"
          id="homeInputReportPc"
          variant="text"
          type="small"
          component={RouterLink}
          to="/"
          sx={{ ...headerItemStyle }}
        >
          報告入力
        </ButtonWithAnalytics>
        <ButtonWithAnalytics
          eventName="viewProperNouns"
          id="properNounsPc"
          variant="text"
          type="small"
          component={RouterLink}
          to="/proper-nouns"
          sx={{ ...headerItemStyle }}
        >
          辞書の管理
        </ButtonWithAnalytics>
        {tenantOptions?.file_upload_enabled && (
          <ButtonWithAnalytics
            eventName="viewFileUpload"
            id="fileUploadPc"
            variant="text"
            type="small"
            component={RouterLink}
            to="/file-upload"
            sx={{ ...headerItemStyle }}
          >
            ファイルアップロード
          </ButtonWithAnalytics>
        )}
        {tenantPlan?.isProcessedTextBasedPlan && (
          <ButtonWithAnalytics
            eventName="viewUsageSituation"
            id="usageSituationPc"
            variant="text"
            type="small"
            component={RouterLink}
            to="/usage-situation"
            sx={{ ...headerItemStyle }}
          >
            利用状況
          </ButtonWithAnalytics>
        )}
      </Stack>
    </React.Fragment>
  );
};
