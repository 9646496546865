import NoSleep from "nosleep.js";

class NoSleepEnabler {
  constructor() {
    if (!NoSleepEnabler.instance) {
      this.noSleep = new NoSleep();
      NoSleepEnabler.instance = this;
    }

    return NoSleepEnabler.instance;
  }

  enableNoSleep() {
    this.noSleep.enable();
  }

  disableNoSleep() {
    this.noSleep.disable();
  }
}

const instance = new NoSleepEnabler();
Object.freeze(instance);

export default instance;
