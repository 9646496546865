import { Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PropTypes from "prop-types";

const InfomationMessage = ({ message }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }} color="text.secondary">
      <Box
        component="span"
        sx={{
          display: "inline-block",
          marginRight: 1,
          lineHeight: "normal",
        }}
      >
        <InfoIcon />
      </Box>
      {message}
    </Box>
  );
};

InfomationMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default InfomationMessage;
