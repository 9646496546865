import { API } from "@aws-amplify/api";
import { entryRequest } from "./graphql/mutations";
import { onSendRequestId } from "./graphql/subscriptions";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";

async function forceRefreshToken() {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();

  return new Promise((resolve) => {
    cognitoUser.refreshSession(currentSession.refreshToken, (err) => {
      if (err) {
        /* ('23/11/10) この例外処理は現在動作しない(TAISORADI-351) */
        console.error(err);
        throw Error("Token refresh error", {
          cause: { code: "tokenRefreshError" },
        });
      }
      resolve();
    });
  });
}

export const chat = async (message, mode, promptId, inputLang, outputLang) => {
  try {
    await forceRefreshToken();
    const rep = await API.graphql({
      query: entryRequest,
      variables: {
        request: message,
        mode: mode,
        promptId: promptId,
        inputLang: inputLang,
        outputLang: outputLang,
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });
    const subscription = await API.graphql({
      query: onSendRequestId,
      variables: {
        id: rep.data.entryRequest,
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });
    return new Promise((resolve, reject) => {
      subscription.subscribe({
        next: (eventData) => {
          const requestItem = eventData.value.data.onSendRequestId;
          const response = JSON.parse(requestItem.response);
          if (response.statusCode !== 200) {
            // TODO: Fix error message.
            const responceBody = JSON.parse(response.body);
            let code = responceBody.code;

            if (responceBody.code === "429") {
              code = "exceeded_available_rate";
            }

            reject({
              message: responceBody.message,
              cause: { code: code },
            });
          }
          resolve(response.body);
        },
      });
    });
  } catch (error) {
    let message = error.errors[0].message;
    let code = "";

    if (message === "Exceeding number of characters") {
      code = "exceeding_number_of_characters";
    } else if (message === "Invalid Parameter Value") {
      code = "invalid_parameter_value";
    }
    throw Error(message, {
      cause: { code: code },
    });
  }
};
