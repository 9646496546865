import { API } from "@aws-amplify/api";
import { createTenantSummarizedTextCount } from "./graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";
export const CreateSummarizedTextCount = async (
  startTime,
  textCount,
  summaryMode,
  userId,
  inputLang,
  outputLang,
  tenantId,
) => {
  try {
    await API.graphql({
      query: createTenantSummarizedTextCount,
      variables: {
        input: {
          summaryStartDateTime: startTime,
          textCount: textCount,
          summaryMode: summaryMode,
          userId: userId,
          inputLang: inputLang,
          outputLang: outputLang,
          tenantId: tenantId,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });
  } catch (error) {
    console.error(error);
    return null;
  }
};
