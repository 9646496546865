export const languageModeOptions = Object.freeze({
  japanese: { value: "ja", label: "日本語" },
  english: { value: "en", label: "英語" },
});

export const downloadAndSharingLabels = Object.freeze({
  en: {
    normal: {
      input: "Input of Report Content",
      output: "Report Content",
    },
    meeting: {
      input: "Transcription of Meeting",
      output: "Minutes of Meeting",
    },
    customPrompt: {
      input: "Input",
      output: "Result",
    },
  },
  ja: {
    normal: {
      input: "報告内容の入力",
      output: "報告内容",
    },
    meeting: {
      input: "会議の文字起こし",
      output: "議事録",
    },
    customPrompt: {
      input: "入力",
      output: "結果",
    },
  },
});
