import { useState } from "react";

export function useMessageState() {
  const [messageInput, setMessageInput] = useState("");
  const [messageOutput, setMessageOutput] = useState("");

  const handleMessageInputChange = (newValue) => {
    setMessageInput(newValue);
  };
  const handleMessageOutputChange = (newValue) => {
    setMessageOutput(newValue);
  };

  return {
    messageInput,
    messageOutput,
    handleMessageInputChange,
    handleMessageOutputChange,
  };
}
