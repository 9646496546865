import ProperNounsSettingRepository from "repositories/ProperNounsSettingRepository";
import { properNounCategories } from "constants/properNounCategories";
import useWordRegister from "hooks/useWordRegister";

const PERSONAL_PROPER_NOUN = properNounCategories.personal;

const useProperNounsSettings = () => {
  const { loadCorporateProperNounsCategories } = useWordRegister();
  const setInitialProperNounStatus = (categoryId, initialStatus) => {
    ProperNounsSettingRepository.updateSettings(categoryId, initialStatus);
  };
  const properNounsSettings = ProperNounsSettingRepository.loadSettings();

  const setInitialProperNounsSettings = async () => {
    const response = await loadCorporateProperNounsCategories();
    let uniqueCategories = [];
    if (response && response.length > 0) {
      uniqueCategories = Array.from(
        response.map((category) => ({
          id: category.id,
          name: category.name,
          initialStatus: category?.initialStatus
            ? category.initialStatus
            : false,
        })),
      );
    }
    uniqueCategories.unshift(PERSONAL_PROPER_NOUN);
    uniqueCategories.map((category) => {
      if (!properNounsSettings[category.id]) {
        setInitialProperNounStatus(category.id, category.initialStatus);
      }
    });
  };
  return { setInitialProperNounsSettings };
};

export default useProperNounsSettings;
