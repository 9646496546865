import { API } from "aws-amplify";
import { listCustomPromptMenus } from "graphql/queries";

export default class CustomPromptMenuRepository {
  async findAll() {
    const result = await API.graphql({
      query: listCustomPromptMenus,
      variables: {
        limit: 10000,
      },
    });

    // Sorting is available in DataStore but not in AppSync.
    // https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js/
    // Sort by id in ascending order.
    const sortedList = result.data.listCustomPromptMenus.items.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });

    return sortedList;
  }
}
