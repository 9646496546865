import React from "react";
import AnalyticsWithTenant from "../helper/AnalyticsWithTenant";
import { Button, ListItemButton, IconButton, MenuItem } from "@mui/material";
import PropTypes from "prop-types";

const ComponentWithAnalytics = (WrappedComponent) => {
  const WithAnalytics = ({ onClickFunc, eventName, children, ...props }) => {
    const handleClick = async (event) => {
      if (onClickFunc) {
        onClickFunc(event);
      }
      if (eventName) {
        try {
          await AnalyticsWithTenant.record({
            name: eventName,
          });
        } catch (error) {
          console.error("Failed to record analytics event:", error);
        }
      }
    };

    return (
      <WrappedComponent {...props} onClick={handleClick} autoFocus>
        {children}
      </WrappedComponent>
    );
  };

  WithAnalytics.propTypes = {
    eventName: PropTypes.string,
    children: PropTypes.node,
    onClickFunc: PropTypes.func,
  };

  return WithAnalytics;
};

const ButtonWithAnalytics = ComponentWithAnalytics((props) => (
  <Button {...props} />
));
const ListItemButtonWithAnalytics = ComponentWithAnalytics((props) => (
  <ListItemButton {...props} />
));
const IconButtonWithAnalytics = ComponentWithAnalytics((props) => (
  <IconButton {...props} />
));
const MenuItemWithAnalytics = ComponentWithAnalytics((props) => (
  <MenuItem {...props} />
));

export {
  ButtonWithAnalytics,
  ListItemButtonWithAnalytics,
  IconButtonWithAnalytics,
  MenuItemWithAnalytics,
};
