import { getResultFromUploadedFile } from "../graphql/queries";
import { API } from "@aws-amplify/api";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";
import AnalyticsWithTenant from "./AnalyticsWithTenant";
import { languageModeOptions } from "../constants/languageModeOptions";

const validateSessionId = (sessionId) => {
  const regex =
    /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;
  return regex.test(sessionId);
};

export const getTranscriptDataBySessionId = (
  enqueueSnackbar,
  handleMessageInputChange,
  tenantOptions,
  setInputLanguageMode,
  setOutputLanguageMode,
  stopIntervalVoiceCountRequest,
) => {
  let message = "文字起こし結果の取得に失敗しました。";
  try {
    const sessionId = sessionStorage.getItem("sessionId");
    const fetchVoiceRecognitionData = async () => {
      return await API.graphql({
        query: getResultFromUploadedFile,
        variables: { sessionId: sessionId },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });
    };

    if (sessionId) {
      if (!validateSessionId(sessionId)) {
        message = "指定されたセッションIDが不正です。URLを確認してください。";
        throw new Error("Invalid session ID.");
      }
      fetchVoiceRecognitionData()
        .catch((error) => {
          console.error(error);
          switch (error.errors[0].message) {
            case "Not Authorized": // 権限がない場合は「見つからなかった」ことにする
            case "sessionId Not Found":
              message +=
                "指定されたセッションIDが見つかりませんでした。URLを確認してください。";
              break;
            case "Response Error: NOT FOUND":
              message =
                "文字起こし結果の有効期限が切れています。もう一度ファイルをアップロードしてください。";
              break;
            case "Response Error: UNAUTHORIZED":
              message =
                "文字起こし結果取得中にエラーが発生しました。もう一度リンクをクリックしてください。";
              break;
            default:
          }
          enqueueSnackbar(message, { variant: "error" });
        })
        .then((response) => {
          if (response?.data) {
            const resultText = response.data.getResultFromUploadedFile;
            handleMessageInputChange(resultText);
            message = "文字起こし結果を入力しました。";
            enqueueSnackbar(message, { variant: "success" });
            AnalyticsWithTenant.record({
              name: "fileUploadResultViewed",
            });
          }
        });
    }

    // 言語選択が無効の場合は、デフォルトの日本語に設定する
    if (!tenantOptions?.multi_language_enabled) {
      setInputLanguageMode(languageModeOptions.japanese);
      setOutputLanguageMode(languageModeOptions.japanese);
    }

    // クリーンアップ関数を利用して、画面から離れた時にタイマーを停止する
    return () => {
      stopIntervalVoiceCountRequest();
    };
  } catch (error) {
    console.error(error);
    enqueueSnackbar(message, { variant: "error" });
  } finally {
    sessionStorage.removeItem("sessionId");
  }
};
