import PropTypes from "prop-types";
import { List, ListItem } from "@mui/material";
import parse from "html-react-parser";

export default function TranscriptsList(props) {
  const { label, transcript } = props;

  const highlightLabel = (label, transcript) => {
    const wordToHighlight = new RegExp("(" + label + "):", "gi");
    return transcript
      .replace(/(.*:)\n/g, "$1 ") // 「発言者ラベル：発言」のセットを1行に
      .replace(/\n/g, "<br />") // 残った改行コードをHTMLに変換
      .replace(
        wordToHighlight,
        "<span style='background-color: #ff0'>$1</span>:",
      ); // 対象の発言者ラベルをハイライト
  };

  return (
    <List>
      {transcript.map((transcript, index) => (
        <ListItem
          key={index}
          divider
          sx={{
            padding: "0.875rem 0",
            display: "flex",
          }}
        >
          <div>{parse(highlightLabel(label, transcript))}</div>
        </ListItem>
      ))}
    </List>
  );
}

TranscriptsList.propTypes = {
  label: PropTypes.string,
  transcript: PropTypes.array,
};
