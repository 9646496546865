import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ButtonWithAnalytics } from "../../../components/ComponentWithAnalytics";
import PropTypes from "prop-types";

export default function ClearConfirmationDialog(props) {
  const {
    enqueueSnackbar,
    handleMessageInputChange,
    setLatestSpeakerLabel,
    confirmationDialogOpen,
    setClearConfirmationDialogOpen,
  } = props;

  const closeClearConfirmed = () => {
    handleMessageInputChange("");
    enqueueSnackbar("クリアしました。");
    // 初回と同様の音声ラベル状態にするため、ここでは空文字をセットする
    setLatestSpeakerLabel("");
    setClearConfirmationDialogOpen(false);
  };

  const closeClearNotConfirmed = () => {
    setClearConfirmationDialogOpen(false);
  };

  return (
    <Dialog
      open={confirmationDialogOpen}
      onClose={closeClearNotConfirmed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"クリアしてよろしいですか？"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          テキストエリアに入力されている文字をクリアします。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonWithAnalytics
          eventName="clickCancelClearInput"
          onClickFunc={closeClearNotConfirmed}
        >
          クリアしない
        </ButtonWithAnalytics>
        <ButtonWithAnalytics
          eventName="clickClearInput"
          onClickFunc={closeClearConfirmed}
          autoFocus
        >
          クリアする
        </ButtonWithAnalytics>
      </DialogActions>
    </Dialog>
  );
}

ClearConfirmationDialog.propTypes = {
  enqueueSnackbar: PropTypes.func,
  handleMessageInputChange: PropTypes.func,
  setLatestSpeakerLabel: PropTypes.func,
  confirmationDialogOpen: PropTypes.bool,
  setClearConfirmationDialogOpen: PropTypes.func,
};
