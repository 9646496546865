import { API } from "@aws-amplify/api";
import { createTenantVoiceInputTextCount } from "./graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";
export const CreateVoiceInputTextCount = async (
  inputStartTime,
  textCount,
  inputDuration,
  userId,
  summaryMode,
  tenantId,
) => {
  try {
    await API.graphql({
      query: createTenantVoiceInputTextCount,
      variables: {
        input: {
          voiceInputStartDateTime: inputStartTime,
          textCount: textCount,
          inputDuration: inputDuration,
          userId: userId,
          summaryMode: summaryMode,
          tenantId: tenantId,
        },
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });
  } catch (error) {
    console.error(error);
    return null;
  }
};
