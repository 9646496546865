import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const TextRotator = ({ texts, interval = 6000 }) => {
  const [currentText, setCurrentText] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentText((currentText + 1) % texts.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [currentText, interval, texts]);

  return <div>{texts[currentText]}</div>;
};

export const ProcessingProgress = () => {
  const texts = [
    "解析しています...",
    "ポイントを抽出しています...",
    "校正しています...",
  ];

  return <TextRotator texts={texts} interval={3000} />;
};

TextRotator.propTypes = {
  texts: PropTypes.array,
  interval: PropTypes.number,
};
