import { Box, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

const DefaultPage = ({ children }) => {
  return (
    <Box
      sx={{
        maxWidth: "640px",
        alignItems: "center",
        alignSelf: "stretch",
        margin: (theme) =>
          useMediaQuery(theme.breakpoints.up("md"))
            ? theme.spacing(10)
            : theme.spacing(5),
      }}
    >
      {children}
    </Box>
  );
};

export default DefaultPage;

DefaultPage.propTypes = {
  children: PropTypes.node,
};
