import { Box, Grid, Typography } from "@mui/material";
import useUsageSituation from "../../hooks/useUsageSituation";
import DefaultPage from "../../components/DefaultPage";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { TenantPlanContext } from "components/TenantPlanProvider";
import { useContext } from "react";
import { Navigate } from "react-router-dom";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const message = `処理文字数は目安です。上限まで到達した場合は、サポートまでお問い合わせください。`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "1.5rem",
  borderRadius: "99px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D9D9D9",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary,
  },
}));

export const UsageSituation = () => {
  const theme = useTheme();
  const {
    monthlyTextCount,
    maxMonthlyTextCount,
    hasValidUsage,
    usageRate,
    isLoading,
  } = useUsageSituation();

  const { tenantPlan } = useContext(TenantPlanContext);

  if (
    tenantPlan === null ||
    (tenantPlan && !tenantPlan.isProcessedTextBasedPlan)
  ) {
    // テナントプランがないor利用状況確認画面が無効の場合は報告入力画面にリダイレクト
    return <Navigate to="/" replace />;
  } else if (!tenantPlan) {
    return null; // tenantPlanがロードされるまで一旦nullを返す
  }

  const getUsageDetailText = (monthlyTextCount, maxMonthlyTextCount) => {
    if (isLoading) {
      return "利用状況の取得中です。";
    } else if (!hasValidUsage) {
      return "利用状況が取得できませんでした。";
    } else {
      return `${Number(monthlyTextCount).toLocaleString()}文字 / ${Number(
        maxMonthlyTextCount,
      ).toLocaleString()}文字`;
    }
  };

  return (
    <DefaultPage>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} justifyContent="flex-start">
          <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
            利用状況
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          justifyContent="flex-start"
          sx={{ marginY: theme.spacing(1) }}
        >
          <Box sx={{ textAlign: "left" }}>
            <BorderLinearProgress variant="determinate" value={usageRate} />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mb: theme.spacing(5) }}>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "left" }}
            id="monthlyTextCount"
          >
            {getUsageDetailText(monthlyTextCount, maxMonthlyTextCount)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            sx={{
              textAlign: "left",
            }}
          >
            {message}
          </Typography>
        </Grid>
      </Grid>
    </DefaultPage>
  );
};

export default UsageSituation;
