import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { ButtonWithAnalytics } from "../../../components/ComponentWithAnalytics";

export default function SummaryExecuteButton(props) {
  const {
    messageInput,
    sendMessageAndWaitResponse,
    setLoading,
    loading,
    primaryButtonSize,
  } = props;
  return (
    <>
      {!loading ? (
        <ButtonWithAnalytics
          eventName="clickSummaryButton"
          id="summary-button"
          variant="contained"
          color="secondary"
          disabled={!messageInput}
          onClickFunc={sendMessageAndWaitResponse}
          sx={{
            margin: "0.4rem 0",
            ...primaryButtonSize,
          }}
          startIcon={
            <AutoAwesomeIcon sx={{ width: "15px", margin: "-0.2rem" }} />
          }
        >
          文章をきれいに
        </ButtonWithAnalytics>
      ) : (
        <ButtonWithAnalytics
          eventName="clickSummaryCancelButton"
          id="summary-cancel-button"
          variant="outlined"
          color="monoqlo"
          onClickFunc={() => setLoading(false)}
          sx={{
            margin: "0.4rem 0",
            ...primaryButtonSize,
          }}
          startIcon={<CloseIcon sx={{ width: "15px", margin: "-0.2rem" }} />}
        >
          キャンセル
        </ButtonWithAnalytics>
      )}
    </>
  );
}

SummaryExecuteButton.propTypes = {
  messageInput: PropTypes.string,
  sendMessageAndWaitResponse: PropTypes.func,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  primaryButtonSize: PropTypes.object,
};
