import WordRepository from "../repositories/WordRepository";
import CorporateProperNounsRepository from "../repositories/CorporateProperNounsRepository";
import CorporateProperNounCategoryRepository from "../repositories/CorporateProperNounsCategoryRepository";
import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const corporateProperNounsRepository = new CorporateProperNounsRepository();
const corporateProperNounCategoryRepository =
  new CorporateProperNounCategoryRepository();

const useWordRegister = () => {
  const schema = yup.object().shape({
    profiledWords: yup.array().of(
      yup.object().shape({
        word: yup
          .string()
          // required
          .required("入力してください")
          // max 60 characters
          .max(60, "60文字以内で入力してください")
          // no |, :, or spaces
          .matches(
            /^[^|:_]+$/,
            `"|"(半角縦棒)、":"(半角コロン)、"_"(半角アンダースコア)を含む文字列は登録できません。`,
          ),
        reading: yup
          .string()
          // required
          .required("入力してください")
          // only hiragana, katakana
          .matches(/^[ぁ-ゔァ-ヴー]+$/, "全角かな、全角カナを入力してください")
          // no spaces
          .matches(/^[^\s]+$/, "スペースは入力しないでください")
          // max 20 characters
          .max(20, "20文字以内で入力してください"),
      }),
    ),
  });

  const {
    register: registerProfiledWords,
    setValue: setProfiledWordsValue,
    control,
    handleSubmit: submitProfiledWords,
    formState: { errors: registerProfiledWordsErrors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      profiledWords: [{ word: "", reading: "" }],
    },
  });
  const { fields: profiledWordsFileds, append } = useFieldArray({
    control,
    name: "profiledWords",
  });

  const [personalRegisteredWords, setPersonalRegisteredWords] = useState([]);
  const [corporateProperNouns, setCorporateProperNouns] = useState([]);

  useEffect(() => {
    (async () => {
      await loadCorporateProperNouns();
      await loadCorporateProperNounsCategories();
      await loadPersonalRegisteredWords();
    })();
  }, []);

  const updateProfiledWords = (index, list) => {
    WordRepository.updateWord(index, list);
  };

  const addProfiledWords = () => {
    append({ word: "", reading: "" });
  };

  const removeProfiledWords = (index) => {
    WordRepository.removeWord(index);
  };

  const loadProfiledWords = () => {
    const loadedWordList = WordRepository.loadWords();
    setProfiledWordsValue("profiledWords", loadedWordList);
    return loadedWordList;
  };

  const loadPersonalRegisteredWords = () => {
    const personalRegisteredWordList = WordRepository.loadWords();
    setPersonalRegisteredWords(personalRegisteredWordList);
    return personalRegisteredWordList;
  };

  const loadCorporateProperNouns = async () => {
    const corporateProperNounList =
      await corporateProperNounsRepository.findAll();
    setCorporateProperNouns(corporateProperNounList);
    return corporateProperNounList;
  };

  const loadCorporateProperNounsCategories = async () => {
    const corporateProperNounsCategoryList =
      await corporateProperNounCategoryRepository.findAll();
    return corporateProperNounsCategoryList;
  };

  const convertListToString = (list) => {
    return list
      .map((item) => `${item.word.replace(/\u0020/g, "_")} ${item.reading}`)
      .join("|");
  };

  const saveProfiledWords = async (list) => {
    await WordRepository.saveWords(list);
  };

  const bulkAddProfiledWords = (words) => {
    if (words.length === 0) {
      throw new Error("辞書が記載されていないか、ヘッダ行のみがあります。");
    }
    words.forEach((word) => {
      append({ word: word.word, reading: word.reading });
    });
  };

  return {
    profiledWordsFileds,
    registerProfiledWordsErrors,
    registerProfiledWords,
    addProfiledWords,
    saveProfiledWords,
    updateProfiledWords,
    loadProfiledWords,
    personalRegisteredWords,
    loadPersonalRegisteredWords,
    removeProfiledWords,
    convertListToString,
    submitProfiledWords,
    bulkAddProfiledWords,
    corporateProperNouns,
    loadCorporateProperNouns,
    loadCorporateProperNounsCategories,
  };
};

export default useWordRegister;
