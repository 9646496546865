import { API } from "aws-amplify";
import { listCorporateProperNouns } from "../graphql/queries";

export default class CorporateProperNounRepository {
  async findAll() {
    const result = await API.graphql({
      query: listCorporateProperNouns,
      variables: {
        limit: 10000,
      },
    });

    // Sorting is available in DataStore but not in AppSync.
    // https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js/
    // Sort by id in ascending order.
    const sortedList = result.data.listCorporateProperNouns.items.sort(
      (a, b) => {
        return parseInt(a.id) - parseInt(b.id);
      },
    );

    return sortedList;
  }
}
