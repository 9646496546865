class SummarizationSettingRepository {
  static loadSettings() {
    const summarizationSettings = localStorage.getItem("summarizationSettings");

    if (summarizationSettings) {
      return JSON.parse(summarizationSettings);
    }
    return {};
  }

  static updateSettings(key, value) {
    const summarizationSettings = this.loadSettings();
    summarizationSettings[key] = value;
    localStorage.setItem(
      "summarizationSettings",
      JSON.stringify(summarizationSettings),
    );
  }
}

export default SummarizationSettingRepository;
