/**
amiVoiceのAPIに渡す登録単語パラメータを生成する関数

amivoice公式Docs
 - https://docs.amivoice.com/amivoice-api/manual/user-guide/function/dictionary
 - https://docs.amivoice.com/amivoice-api/manual/user-guide/request/request-parameters#%E5%8D%98%E8%AA%9E%E7%99%BB%E9%8C%B2%E3%83%AA%E3%82%B9%E3%83%88-profilewords
**/

// import useWordRegister from "hooks/useWordRegister";
import ProperNounsSettingRepository from "../../repositories/ProperNounsSettingRepository";
import { properNounCategories } from "constants/properNounCategories";

//react hooksは関数コンポーネントでしか使用できないので，hooksから取得できる関数を引数として受け取る必要がある
export default async function createProfileWordsParameter(
  loadProfiledWords,
  convertListToString,
  loadCorporateProperNouns,
) {
  const convertCorporatePronounListToString = (corporatePronounList) => {
    return corporatePronounList
      .map((item) => `${item.writing.replace(/\u0020/g, "_")} ${item.reading}`)
      .join("|");
  };

  // 辞書設定を取得する
  const properNounSettings = ProperNounsSettingRepository.loadSettings();
  // properNounSettingsObj ex.{"marketing":{"isValid":true},"dev":{"isValid":true}}
  // properNounSettingsのisValidの値がtrueのもののみを配列で取得する
  const properNounSettingsArray = Object.keys(properNounSettings).filter(
    (key) => properNounSettings[key].isValid,
  );
  // TODO: カテゴリのソート順を反映する

  // Refresh registered words in Wrp.
  let pronounsStr = "";

  const corporateProperNouns = await loadCorporateProperNouns();
  // categoryIdがproperNounSettingsArrayに含まれるもののみにフィルターする
  const filteredCorporateProperNouns = corporateProperNouns.filter((item) =>
    properNounSettingsArray.includes(item.categoryId),
  );
  if (filteredCorporateProperNouns && filteredCorporateProperNouns.length > 0) {
    pronounsStr = convertCorporatePronounListToString(
      filteredCorporateProperNouns,
    );
  }

  // プライベート辞書が適用中の場合のみ、プライベート辞書に単語を追加する
  if (properNounSettings[properNounCategories.personal.id]?.isValid) {
    const words = await loadProfiledWords();
    if (words && words.length > 0) {
      const profiledWordsConverted = convertListToString(words);
      if (pronounsStr) {
        pronounsStr = `${pronounsStr}|${profiledWordsConverted}`;
      } else {
        pronounsStr = profiledWordsConverted;
      }
    }
  }
  // console.log("pronounsStr", pronounsStr);
  return pronounsStr;
}
