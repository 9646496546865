import { API } from "aws-amplify";
import { listOptionSettings } from "../graphql/queries";

export default class TenantOptionSettingsRepository {
  async getTenantOptionSettings() {
    try {
      const result = await API.graphql({
        query: listOptionSettings,
      });

      // データの存在をチェック
      if (
        result.data &&
        result.data.listOptionSettings &&
        result.data.listOptionSettings.items.length > 0
      ) {
        // 最初の要素を返す
        return result.data.listOptionSettings.items[0];
      } else {
        // 適切なデータが見つからない場合の処理
        console.error("No option settings found.");
        return null; // または適切なデフォルト値
      }
    } catch (error) {
      // エラーハンドリング
      console.error("Error fetching tenant option settings:", error);
      throw error; // エラーを再スローするか、適切なエラー処理を行う
    }
  }
}
