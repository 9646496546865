import { useContext } from "react";
import { TenantOptionsContext } from "./TenantOptionsProvider";
import parse from "html-react-parser";

export const TenantCustomMessage = () => {
  const { tenantOptions } = useContext(TenantOptionsContext);
  try {
    if (tenantOptions && tenantOptions.custome_message_enabled) {
      const message = parse(tenantOptions.custome_message_contents);
      return <div id="custom_message">{message}</div>;
    } else {
      return <div id="custom_message"></div>;
    }
  } catch (error) {
    console.error("Error updating custom message:", error);
    return <div id="custom_message"></div>;
  }
};
