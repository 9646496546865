import { Link, Typography } from "@mui/material";
import PropTypes from "prop-types";

const TextWithHereLink = ({ text, onClick }) => {
  return (
    <Typography
      align="left"
      variant="subtitle1"
      sx={{
        textAlign: "center",
      }}
    >
      {text}
      <Link
        href="#"
        onClick={(event) => {
          event.preventDefault();
          onClick(event);
        }}
      >
        こちら
      </Link>
    </Typography>
  );
};

TextWithHereLink.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default TextWithHereLink;
