export const getTenantUserGroup = (userInfo) => {
  const groups = userInfo["cognito:groups"];
  const userGroups = groups.filter((group) => group !== "APIUser");

  if (userGroups.length > 1) {
    const error = new Error("Found more than one tenant ID", {
      code: "multipleTenantId",
    });
    throw error;
  } else if (userGroups.length === 0) {
    const error = new Error("User does not belong to any tenant", {
      code: "noTenantId",
    });

    throw error;
  }

  return userGroups[0];
};
