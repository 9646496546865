import { Box, TextField, Typography } from "@mui/material";
import { ButtonWithAnalytics } from "components/ComponentWithAnalytics";
import PropTypes from "prop-types";
import useWordRegister from "../../hooks/useWordRegister";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@mui/material/styles";
import { isIphone } from "../../helper/deviceHelper";

const WordSchema = yup.object().shape({
  word: yup
    .string()
    // required
    .required("入力してください")
    // max 60 characters
    .max(60, "60文字以内で入力してください")
    // no |, :, or spaces
    .matches(
      /^[^|:_]+$/,
      `"|"(半角縦棒)、":"(半角コロン)、"_"(半角アンダースコア)を含む文字列は登録できません。`,
    ),
  reading: yup
    .string()
    // required
    .required("入力してください")
    // only hiragana, katakana
    .matches(/^[ぁ-ゔァ-ヴー]+$/, "全角かな、全角カナを入力してください")
    // no spaces
    .matches(/^[^\s]+$/, "スペースは入力しないでください")
    // max 20 characters
    .max(20, "20文字以内で入力してください"),
});

const primaryButtonSize = {
  width: "10rem",
};

export default function EditWord() {
  const theme = useTheme();
  const { updateProfiledWords, removeProfiledWords } = useWordRegister();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(WordSchema),
  });

  const location = useLocation();

  function isSetWordIndex() {
    if (location.state === null) {
      return false;
    }
    return true;
  }
  const wordIndex = isSetWordIndex() ? location.state.index : null;
  const wordWord = isSetWordIndex() ? location.state.word : null;
  const wordReading = isSetWordIndex() ? location.state.reading : null;

  const successSaveProfiledWordsMessage = "登録単語を追加しました。";
  const successRemoveProfiledWordsMessage = "登録単語を削除しました。";

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    await updateProfiledWords(wordIndex, data);
    navigate("/word-registration");
    enqueueSnackbar(successSaveProfiledWordsMessage, { variant: "success" });
  };

  function removeWord() {
    removeProfiledWords(wordIndex);
    navigate("/word-registration");
    enqueueSnackbar(successRemoveProfiledWordsMessage, { variant: "success" });
  }

  //  https://codesandbox.io/s/react-hook-form-v7-validationschema-rm35t?file=/src/index.js:583-587
  return (
    <Box
      sx={{
        paddingX: { xs: theme.spacing(3) },
        width: { md: "652px" },
        margin: "auto",
      }}
    >
      {isSetWordIndex() ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box marginX="1rem" sx={{ display: { xs: "block", md: "none" } }}>
              <ButtonWithAnalytics
                eventName="clickSaveWord"
                type="submit"
                variant="text"
                color="primary"
                size="small"
                sx={{
                  position: "fixed",
                  zIndex: "10000",
                  top: "0.4rem",
                  right: "0",
                }}
              >
                保存
              </ButtonWithAnalytics>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "auto",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "0.25rem",
                mt: { xs: theme.spacing(5), md: theme.spacing(10) },
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ margin: "0", textAlign: "left" }}
              >
                表記
              </Typography>
              <TextField
                id="personalPropernounWordTextfeild"
                fullWidth
                variant="outlined"
                sx={{ mt: "0", mb: "0.4rem" }}
                defaultValue={wordWord}
                {...register("word")}
                error={!!errors?.word?.message}
                helperText={errors?.word?.message}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "auto",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "0.25rem",
                mt: { xs: theme.spacing(5) },
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ margin: "0", textAlign: "left" }}
              >
                読み
              </Typography>
              <TextField
                id="personalPropernounReadingTextfeild"
                fullWidth
                variant="outlined"
                sx={{ mt: "0", mb: "1.25rem" }}
                defaultValue={wordReading}
                {...register("reading")}
                error={!!errors?.reading?.message}
                helperText={errors?.reading?.message}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                maxWidth: "auto",
                justifyContent: "center",
                gap: "0.75rem",
                mb: "1.5rem",
              }}
            >
              <ButtonWithAnalytics
                eventName="clickCancelEditWord"
                type="submit"
                variant={isIphone() ? "text" : "outlined"}
                color="monoqlo"
                onClickFunc={() => navigate("/word-registration")}
                sx={{ ...primaryButtonSize }}
              >
                キャンセル
              </ButtonWithAnalytics>
              <ButtonWithAnalytics
                eventName="clickSaveWord"
                id="saveWordPc"
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  ...primaryButtonSize,
                  display: { xs: "none", md: "block" },
                }}
              >
                保存
              </ButtonWithAnalytics>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "auto",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ButtonWithAnalytics
                eventName="clickDeleteWord"
                id="deleteWord"
                variant="text"
                size="small"
                sx={{ height: "0.875rem", color: "#e8415b" }}
                onClickFunc={() => removeWord()}
              >
                削除
              </ButtonWithAnalytics>
            </Box>
          </Box>
        </form>
      ) : (
        navigate("/word-registration")
      )}
    </Box>
  );
}

EditWord.propTypes = {
  updateProfiledWords: PropTypes.func,
  removeProfiledWords: PropTypes.func,
};
