import * as React from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { MainListItems } from "./listItems";
import { MainListHeaderItems } from "./listHeaderItems";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";

import CopyrightText from "./CopyrightText";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Avatar, Typography, useMediaQuery } from "@mui/material";
import {
  ButtonWithAnalytics,
  IconButtonWithAnalytics,
  ListItemButtonWithAnalytics,
  MenuItemWithAnalytics,
} from "./ComponentWithAnalytics";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import LaunchIcon from "@mui/icons-material/Launch";
import { useTheme } from "@emotion/react";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(theme.breakpoints.down("md") && {
    marginLeft: 0,
    width: "100%",
    ...(open && {
      marginLeft: 0,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
  }),
}));

const Drawer = MuiDrawer;

function stringAvatar(username) {
  return {
    children: `${username.split(/[.\-_]/)[0][0]}${
      username.split(/[.\-_]/)[1][0]
    }`,
  };
}

export default function AppContentWrapper(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const { user, signOut } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ]);
  const navigate = useNavigate();

  async function logOut() {
    props.handleMessageInputChange("");
    props.handleMessageOutputChange("");
    signOut();
    navigate("/login");
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButtonWithAnalytics
              eventName="clickAppBarMenu"
              id="appBarMenuSp"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClickFunc={toggleDrawer}
              sx={{
                marginRight: "36px",
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuIcon />
            </IconButtonWithAnalytics>

            {/* center */}
            <Box
              sx={{
                flexGrow: 1,
                position: "absolute",
                left: "0%",
                transform: "translate(193px, 0)",
                display: { xs: "none", md: "block" },
              }}
            >
              <MainListHeaderItems />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                position: "absolute",
                left: { xs: "50%", md: "0%" },
                transform: {
                  md: "translate(20px, 0)",
                },
                display: { xs: "none", md: "block" },
                height: "29px",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/horizontalLogoMascot.svg`}
                style={{
                  width: "9rem",
                  display: "block",
                  marginLeft: "0",
                  marginTop: "0px",
                }}
                draggable="false"
              />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                position: "absolute",
                left: { xs: "50%", md: "0%" },
                transform: {
                  xs: "translate(-50%, 0)",
                },
                display: { xs: "block", md: "none" },
                height: "24px",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/horizontalLogo.svg`}
                style={{
                  width: "7rem",
                  display: "block",
                  marginLeft: "0",
                  marginTop: "0px",
                }}
                draggable="false"
              />
            </Box>

            <ButtonWithAnalytics
              eventName="clickAppBarMenu"
              id="appBarMenuPc"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              aria-controls={openMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              onClickFunc={handleClick}
              sx={{
                position: "fixed",
                zIndex: "10000",
                top: "0.4rem",
                right: "2.5rem",
                display: { xs: "none", md: "block" },
                fontWeight: "300",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Avatar
                  {...stringAvatar(user.attributes.email)}
                  sx={{ width: 36, height: 36, mx: "0.5rem" }}
                />
                <Typography sx={{ mr: "1.25rem", fontSize: "1rem" }}>
                  {user.attributes.email}
                </Typography>
                <MenuIcon />
              </Box>
            </ButtonWithAnalytics>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                "& .MuiPaper-root": { backgroundColor: "#E3E8EC" },
                "& .MuiList-padding": { p: 0 },
              }}
            >
              <MenuItemWithAnalytics
                eventName="viewManual"
                id="openManual"
                onClickFunc={() => window.open("./manual.pdf", "_blank")}
                sx={{ p: 6, width: "360px" }}
              >
                <ListItemIcon>
                  <LaunchIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText disableTypography sx={{ ml: "-0.75rem" }}>
                  マニュアル
                </ListItemText>
              </MenuItemWithAnalytics>
              <MenuItemWithAnalytics
                eventName="viewPrivacyPolicy"
                id="openPrivacyPolicy"
                onClickFunc={() =>
                  window.open("/privacy_policy.html", "_blank")
                }
                sx={{ p: 6, width: "360px" }}
              >
                <ListItemIcon>
                  <DescriptionOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText disableTypography sx={{ ml: "-0.75rem" }}>
                  プライバシーポリシー
                </ListItemText>
              </MenuItemWithAnalytics>
              <MenuItemWithAnalytics
                eventName="viewTermsOfService"
                id="openTermsOfService"
                onClickFunc={() =>
                  window.open("/terms_of_service.html", "_blank")
                }
                sx={{ p: 6, width: "360px" }}
              >
                <ListItemIcon>
                  <LibraryBooksOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText disableTypography sx={{ ml: "-0.75rem" }}>
                  利用規約
                </ListItemText>
              </MenuItemWithAnalytics>
              <MenuItemWithAnalytics
                eventName="viewDataPolicy"
                id="openDataPolicy"
                onClickFunc={() => window.open("/data_policy.pdf", "_blank")}
                sx={{ p: 6, width: "360px" }}
              >
                <ListItemIcon>
                  <DescriptionOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText disableTypography sx={{ ml: "-0.75rem" }}>
                  データポリシー
                </ListItemText>
              </MenuItemWithAnalytics>
              <MenuItemWithAnalytics
                eventName="clickLogout"
                id="proceedLogout"
                onClickFunc={() => logOut()}
                sx={{ p: 6, width: "360px" }}
              >
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText disableTypography sx={{ ml: "-0.75rem" }}>
                  ログアウト
                </ListItemText>
              </MenuItemWithAnalytics>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="left"
          variant="temporary"
          open={open}
          onClose={() => setOpen(false)}
        >
          <Box sx={{ width: drawerWidth, height: "100%" }}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: open ? "flex-end" : "flex-start",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </Toolbar>
            <List component="nav" sx={{ p: 0 }}>
              <MainListItems onClickMenu={() => setOpen(false)} />
              <ListItemButtonWithAnalytics
                eventName="clickLogout"
                divider
                onClickFunc={() => logOut()}
              >
                <ListItemText primary="ログアウト" />
                <IconButton aria-label="edit" sx={{ p: 0 }}>
                  <ArrowForwardIosIcon sx={{ width: "14px" }} />
                </IconButton>
              </ListItemButtonWithAnalytics>
            </List>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <img
              src={`${process.env.PUBLIC_URL}/ninja_stand_icon.svg`}
              style={{ width: "5.75rem", paddingBottom: theme.spacing(3) }}
              draggable="false"
            />
            <CopyrightText sx={{ pb: "1.25rem" }} />
          </Box>
        </Drawer>
        <Box
          id="main"
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" &&
              useMediaQuery(theme.breakpoints.up("md"))
                ? "#ecf3f7"
                : "#ffffff",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Grid container justifyContent={"center"}>
            {props.children}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

AppContentWrapper.propTypes = {
  children: PropTypes.node,
  props: PropTypes.any,
  handleMessageInputChange: PropTypes.func,
  handleMessageOutputChange: PropTypes.func,
};
