import { API } from "aws-amplify";
import { listMonthlyTenantProcessedTextCounts } from "../graphql/queries";
import { updateMonthlyProcessedTextCount } from "../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";

export default class MonthlyTenantProcessedTextCountRepository {
  async find(tenantId, isActive) {
    // TODO: listクエリで取得した結果をフィルタするのではなく、キーを設定するなどテーブル設計を改善して一発で取得するようにする。
    // 今は機能をリリースすることを優先する。
    const variables = {
      filter: {
        tenantId: {
          eq: tenantId,
        },
        activatedStatus: {
          eq: isActive,
        },
      },
      limit: 10000, // デフォルトだと100件だったと思うので暫定対応
    };

    const result = await API.graphql({
      query: listMonthlyTenantProcessedTextCounts,
      variables: variables,
    });

    const items = result.data.listMonthlyTenantProcessedTextCounts?.items;

    if (items.length > 1) {
      throw Error(
        "More than 2 active MonthlyTenantProcessedTextCount items found. expected 1 or less",
        {
          cause: { code: "moreThan2ActiveMonthlyTenantProcessedTextCount" },
        },
      );
    }
    return items[0];
  }

  /**
   * Increments the count of processed text for a tenant in a month.
   *
   * @param {number} incrementValue - The value to increment the count by.
   * @returns {Promise<Object>} The response from the API call.
   * @throws {Error} If the response is not a string or if the response body contains an error.
   */
  async incrementMonthlyTenantProcessedTextCount(incrementValue) {
    const res = await API.graphql({
      query: updateMonthlyProcessedTextCount,
      variables: {
        incrementValue: incrementValue,
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });

    let response = res?.data?.updateMonthlyProcessedTextCount;

    if (typeof response !== "string") {
      throw Error("response is not string");
    } else {
      response = JSON.parse(response);
    }

    if (response.body.error) {
      throw Error(response.body.error);
    }

    return response;
  }
}
