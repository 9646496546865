import { API } from "@aws-amplify/api";
import { getAmiVoiceOneTimeAppKey } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";

export const getVoiceRecognitionAPIOneTimeAppKey = async (
  isVoiceFileUpload = false,
) => {
  try {
    const res = await API.graphql({
      query: getAmiVoiceOneTimeAppKey,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      variables: { msg: isVoiceFileUpload ? "voiceFileUpload" : null },
    });
    const apiKey = JSON.parse(res.data.getAmiVoiceOneTimeAppKey).body
      .oneTimeAppKey;
    return apiKey;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
