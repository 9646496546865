import * as React from "react";
import { ListItemText, IconButton } from "@mui/material";
import { ListItemButtonWithAnalytics } from "./ComponentWithAnalytics";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PropTypes from "prop-types";
import { TenantPlanContext } from "./TenantPlanProvider";
import { useContext } from "react";

export const MainListItems = ({ onClickMenu }) => {
  const navigate = useNavigate();
  const { tenantPlan } = useContext(TenantPlanContext);

  return (
    <React.Fragment>
      <ListItemButtonWithAnalytics
        eventName="viewHomeInputReport"
        divider
        onClickFunc={() => {
          navigate("/dashboard");
          onClickMenu();
        }}
      >
        <ListItemText primary="報告入力" />
        <IconButton aria-label="edit" sx={{ p: 0 }}>
          <ArrowForwardIosIcon sx={{ width: "14px" }} />
        </IconButton>
      </ListItemButtonWithAnalytics>
      <ListItemButtonWithAnalytics
        eventName="viewProperNouns"
        divider
        onClickFunc={() => {
          navigate("/proper-nouns");
          onClickMenu();
        }}
      >
        <ListItemText primary="辞書の管理" />
        <IconButton aria-label="edit" sx={{ p: 0 }}>
          <ArrowForwardIosIcon sx={{ width: "14px" }} />
        </IconButton>
      </ListItemButtonWithAnalytics>
      {tenantPlan?.isProcessedTextBasedPlan && (
        <ListItemButtonWithAnalytics
          eventName="viewUsageSituation"
          divider
          onClickFunc={() => {
            navigate("/usage-situation");
            onClickMenu();
          }}
        >
          <ListItemText primary="利用状況" />
          <IconButton aria-label="edit" sx={{ p: 0 }}>
            <ArrowForwardIosIcon sx={{ width: "14px" }} />
          </IconButton>
        </ListItemButtonWithAnalytics>
      )}
      <ListItemButtonWithAnalytics
        eventName="viewManual"
        divider
        onClickFunc={() => window.open("/manual.pdf", "_blank")}
      >
        <ListItemText primary="マニュアル" />
        <IconButton aria-label="edit" sx={{ p: 0 }}>
          <ArrowForwardIosIcon sx={{ width: "14px" }} />
        </IconButton>
      </ListItemButtonWithAnalytics>
      <ListItemButtonWithAnalytics
        eventName="viewPrivacyPolicy"
        divider
        onClickFunc={() => window.open("/privacy_policy.html", "_blank")}
      >
        <ListItemText primary="プライバシーポリシー" />
        <IconButton aria-label="edit" sx={{ p: 0 }}>
          <ArrowForwardIosIcon sx={{ width: "14px" }} />
        </IconButton>
      </ListItemButtonWithAnalytics>
      <ListItemButtonWithAnalytics
        eventName="viewTermsOfService"
        divider
        onClickFunc={() => window.open("/terms_of_service.html", "_blank")}
      >
        <ListItemText primary="利用規約" />
        <IconButton aria-label="edit" sx={{ p: 0 }}>
          <ArrowForwardIosIcon sx={{ width: "14px" }} />
        </IconButton>
      </ListItemButtonWithAnalytics>
      <ListItemButtonWithAnalytics
        eventName="viewDataPolicy"
        divider
        onClickFunc={() => window.open("/data_policy.pdf", "_blank")}
      >
        <ListItemText primary="データポリシー" />
        <IconButton aria-label="edit" sx={{ p: 0 }}>
          <ArrowForwardIosIcon sx={{ width: "14px" }} />
        </IconButton>
      </ListItemButtonWithAnalytics>
    </React.Fragment>
  );
};

MainListItems.propTypes = {
  onClickMenu: PropTypes.func,
};
