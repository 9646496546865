import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { IOSSwitch } from "../../components/Switch/IOSSwitch";
import { isIphone } from "../../helper/deviceHelper";

/**
 * A component that displays a header for the proper noun list.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the header.
 * @param {string} props.message - The message of the header.
 *
 * @returns {JSX.Element} - The component's UI.
 */
const ProperNounListHeader = ({
  title,
  message,
  showSwitch = false,
  onChangeSwitch,
  isChecked,
}) => {
  return (
    <Grid
      container
      sx={{
        margin: "1.25rem 0",
        marginX: { md: 0, xs: "1rem" },
        width: "auto",
      }}
    >
      <Grid item xs={isIphone() ? 10 : 11}>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "left", mb: "0.5rem" }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ textAlign: "left" }}
        >
          {message}
        </Typography>
      </Grid>
      {showSwitch && (
        <Grid item xs={isIphone() ? 2 : 1}>
          <IOSSwitch
            id="properNounSwitch"
            onChange={onChangeSwitch}
            checked={isChecked}
          />
        </Grid>
      )}
    </Grid>
  );
};

ProperNounListHeader.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  showSwitch: PropTypes.bool,
  onChangeSwitch: PropTypes.func,
  isChecked: PropTypes.bool,
};

export default ProperNounListHeader;
