import { API } from "@aws-amplify/api";
import { entryVoiceFile } from "graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";

export const voiceFileUpload = async (sessionId, fileName) => {
  try {
    const result = await API.graphql({
      query: entryVoiceFile,
      variables: {
        sessionId: sessionId,
        filename: fileName,
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
